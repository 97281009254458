<template>
	<div>
		<commonCover></commonCover>
		<div class="logo">
			<img src="@/assets/img/BIOAGE_LOGO.png" alt="">
		</div>
		<div class="menu" @click="menuOn">
			<img src="@/assets/img/menu.png" alt="">
		</div>
		<div class="title4_fixed BIZUDPMincho-Medium" ref="title4_fixed">
		</div>
		<div class="container-mb" ref="container_mb">
			<img class="my_svg2 svg" src="@/assets/img/m/NMN.png" alt="" ref="svg" />
			<img class="my_svg2 svg4" src="@/assets/img/m/PQQ.png" alt="" ref="svg4" />
			<img class="my_svg2 svg6" src="@/assets/img/m/NMN.png" alt="" ref="svg6" />
			<img class="my_svg2 svg8" src="@/assets/img/m/PQQ.png" alt="" ref="svg8" />
			<img class="my_svg2 svg9" src="@/assets/img/m/NMN.png" alt="" ref="svg9" />
			<div class="bg-container-mb" ref="bg_container_mb"></div>
<!--			<section class="panel-mb">
				<video autoplay loop muted playsinline>
					<source src="@/assets/bgM.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="mark"></div>
			</section>-->
			<section id="one" class="panel-mb bg bg1" ref="one">
				<div class="good1" ref="good1">
					<img src="@/assets/img/logo2.png" alt="">
				</div>
				<div class="Baskerville-Old-Face title2_kari" ref="title2_kari">
					<div>No fading</div>
					<div>No aging</div>
					<div>But with BIOAGEN</div>
				</div>
				<div class="title4_box" ref="title4_box"></div>
			</section>
			<section id="one2" class="panel-mb bg bg1" ref="one2">
				<div @click="amazonClick(1)" class="good2" ref="good2">
					<img src="@/assets/img/v3/1_top.png" alt="">
				</div>
				<div class="title4 BIZUDPMincho-Medium" ref="title4">
				</div>
			</section>
			<section id="two" class="panel-mb red bg2" ref="two">
				<div @click="amazonClick(3)" class="good3" ref="good3">
					<img src="@/assets/img/m/3.png" alt="">
				</div>
				<div class="Baskerville-Old-Face title2_kari" ref="title2_kari">
				</div>
				<div @click="amazonClick(2)" class="good4" ref="good4">
					<img src="@/assets/img/v3/2_top.png" alt="">
				</div>
			</section>
			<section id="three" class="panel-mb orange bg3">
				<div @click="amazonClick(4)" class="good7" ref="good7">
					<img src="@/assets/img/v3/3_top.png" alt="">
				</div>
				<div @click="amazonClick(5)" class="good5" ref="good5">
					<img src="@/assets/img/v3/4_top.png" alt="">
				</div>
				<div class="title6 BIZUDPMincho-Medium" ref="title6">
				</div>
			</section>
			<section id="three2" class="panel-mb orange bg3">
				<div class="Baskerville-Old-Face title5_fixed" ref="title5_fixed">
					<div @click="toHealthS">Products</div>
          <div style="margin-bottom: 20px; height: auto;">
            <div style="font-size: 16px; height: 22px; line-height: 22px;" @click="toProduct1">Health Support</div>
            <div style="font-size: 16px; height: 22px; line-height: 22px;" @click="toProduct2">Beauty Support</div>
            <div style="font-size: 16px; height: 22px; line-height: 22px;" @click="toProduct3">Skincare Series</div>
          </div>
					<div @click="toShopList">Shop List</div>
					<div style="display: flex">
						<div style="font-size: 14px" @click="toJitsu">実店舗</div>
						<div style="font-size: 14px;margin-left: 20px" @click="toOnline">オンライン店舗</div>
            <div style="font-size: 14px;margin-left: 20px" @click="toOther">その他のチャネル</div>
					</div>
					<div @click="toAbout">About</div>
					<div @click="toContact">Contact</div>
					<div style="display: flex;margin-top: 20px">
						<a href="https://instagram.com/bioagen" target="_blank">
							<div class="img1">
								<img src="@/assets/img/ins-w.svg" alt="" />
							</div>
						</a>
						<a href="https://lin.ee/HOIdxc2" target="_blank">
							<div class="img2">
								<img src="@/assets/img/v3/LINE_APP_W.png" alt="" />
							</div>
						</a>
						<a href="https://twitter.com/bioagen_japan?s=11&t=oOhE72Qfm-ezlPdR7DaIvQ" target="_blank">
							<div class="img3">
								<img src="@/assets/img/v3/Twitter-w.png" alt="" />
							</div>
						</a>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import commonCover from '../components/commonCover.vue'
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
	name: 'topMB',
	components: {
		commonCover
	},
	setup() {
		const one = ref(null);
		const two = ref(null);
		const title4 = ref(null);
		const title2_kari = ref(null);
		const title4_fixed = ref(null);
		const title4_box = ref(null)
		const title5_fixed = ref(null);
		const title6 = ref(null);
		// const title7 = ref(null);
		const good1 = ref(null);
		const good2 = ref(null);
		const good3 = ref(null);
		const good4 = ref(null);
		const good5 = ref(null);
		// const good7 = ref(null);
		// const good8 = ref(null);
		const svg = ref(null);
		// const svg2 = ref(null);
		// const svg3 = ref(null);
		const svg4 = ref(null);
		// const svg5 = ref(null);
		const svg6 = ref(null);
		// const svg7 = ref(null);
		const svg8 = ref(null);
		const svg9 = ref(null);
		// const svg10 = ref(null);
		const container_mb = ref(null);
		const store = useStore();

		onMounted(() => {
			if (document.documentElement.clientWidth <= 440) {

				let screenHeight = window.innerHeight;

				// svg
				gsap.to(svg.value, { duration: 50, delay: 5, y: -1 * screenHeight, rotation: 1440, ease: "none", repeat: -1 });
				// gsap.to(svg2.value, { duration: 50, delay: 18, y: -1*screenHeight, rotation: 1040, ease: "none", repeat: -1 });
				// gsap.to(svg3.value, { duration: 50, delay: 26, y: -1*screenHeight, rotation: 1140, ease: "none", repeat: -1 });
				gsap.to(svg4.value, { duration: 50, delay: 35, y: -1 * screenHeight, rotation: 1340, ease: "none", repeat: -1 });
				// gsap.to(svg5.value, { duration: 50, delay: 1, y: -1*screenHeight, rotation: 1240, ease: "none", repeat: -1 });
				gsap.to(svg6.value, { duration: 50, delay: 26, y: -1 * screenHeight, rotation: 1940, ease: "none", repeat: -1 });
				// gsap.to(svg7.value, { duration: 50, delay: 4, y: -1*screenHeight, rotation: 1840, ease: "none", repeat: -1 });
				gsap.to(svg8.value, { duration: 50, delay: 20, y: -1 * screenHeight, rotation: 1740, ease: "none", repeat: -1 });
				gsap.to(svg9.value, { duration: 50, delay: 30, y: -1 * screenHeight, rotation: 1640, ease: "none", repeat: -1 });
				// gsap.to(svg10.value, { duration: 50, delay: 5, y: -1*screenHeight, rotation: 1540, ease: "none", repeat: -1 });

				let sections_mb = gsap.utils.toArray(".panel-mb");

				gsap.to(sections_mb, {
					yPercent: -100 * (sections_mb.length - 1),
					ease: "none",
					scrollTrigger: {
						id: "topScrollMB",
						trigger: ".container-mb",
						pin: true,
						scrub: 1,
						snap: 1 / (sections_mb.length - 1),
						end: "+=" + screenHeight,
            onScrubComplete: ({progress, direction, isActive}) => {
              console.log('onScrubComplete');
            },
						onUpdate: (self) => {
							if (one.value) {
								let oneDom_mb = one.value.getBoundingClientRect();
								let title4Dom_mb = title4.value.getBoundingClientRect();
								let title4FixedDom_mb = title4_fixed.value.getBoundingClientRect();
								let title4BoxDom_mb = title4_box.value.getBoundingClientRect();
								let title5FixedDom_mb = title5_fixed.value.getBoundingClientRect();
								let title6Dom_mb = title6.value.getBoundingClientRect();
								// let title7Dom_mb = title7.value.getBoundingClientRect();
								let good1Dom_mb = good1.value.getBoundingClientRect();
								let good2Dom_mb = good2.value.getBoundingClientRect();
								let good3Dom_mb = good3.value.getBoundingClientRect();
								let good4Dom_mb = good4.value.getBoundingClientRect();
								let good5Dom_mb = good5.value.getBoundingClientRect();
								// let good7Dom_mb = good7.value.getBoundingClientRect(); 
								// let good8Dom_mb = good8.value.getBoundingClientRect();

								// bgC
								gsap.to(".bg-container-mb", {
									yPercent: self.progress * 1.5,
									// duration: 0.5,
								});

								gsap.to(title4.value, {
									opacity: title4FixedDom_mb.top > title4Dom_mb.top ? hanyoFade(screenHeight, title4Dom_mb) : 0,
									duration: 0.5,
								});
								gsap.to(title2_kari.value, {
									opacity: title4FixedDom_mb.top > title4Dom_mb.top ? hanyoFade(screenHeight, title4Dom_mb) : 0,
									duration: 0.5,
								});
								gsap.to(title4_fixed.value, {
									opacity: title4FixedDom_mb.top > title4Dom_mb.top || title4FixedDom_mb.bottom < title4BoxDom_mb.top ? 0 : 1,
									duration: 0.5,
								});
								gsap.to(title6.value, {
									opacity: hanyoFade(screenHeight, title6Dom_mb),
									duration: 0.5,
								});
								gsap.to(good1.value, {
									opacity: topFade(screenHeight, good1Dom_mb),
									duration: 0.4,
								});
								gsap.to(good2.value, {
									opacity: hanyoFade(screenHeight, good2Dom_mb),
									duration: 0.4,
									delay: 0.1
								});
								gsap.to(good3.value, {
									opacity: topFade(screenHeight, good3Dom_mb),
									duration: 0.4,
									delay: 0.1
								});
								gsap.to(good4.value, {
									opacity: hanyoFade(screenHeight, good4Dom_mb),
									duration: 0.4,
									delay: 0.1
								});
								gsap.to(good5.value, {
									opacity: topFade(screenHeight, good5Dom_mb),
									duration: 0.4,
									delay: 0.1
								});
								// svg
								gsap.to(svg.value, {
									opacity: ((oneDom_mb.top <= screenHeight || oneDom_mb) && title4FixedDom_mb.top < title4BoxDom_mb.top) ? 0 : 1,
									duration: 0.8,
								});
								gsap.to(svg4.value, {
									opacity: (oneDom_mb.top <= screenHeight && title4FixedDom_mb.top < title4BoxDom_mb.top) ? 0 : 1,
									duration: 0.8,
								});
								gsap.to(svg6.value, {
									opacity: (oneDom_mb.top <= screenHeight && title4FixedDom_mb.top < title4BoxDom_mb.top) ? 0 : 1,
									duration: 0.8,
								});
								gsap.to(svg8.value, {
									opacity: (oneDom_mb.top <= screenHeight && title4FixedDom_mb.top < title4BoxDom_mb.top) ? 0 : 1,
									duration: 0.8,
								});
								gsap.to(svg9.value, {
									opacity: (oneDom_mb.top <= screenHeight && title4FixedDom_mb.top < title4BoxDom_mb.top) ? 0 : 1,
									duration: 0.8,
								});
							}
						},
					}
				});
			}
		});

		onUnmounted(() => {
			let topScrollMB = ScrollTrigger.getById("topScrollMB");
			topScrollMB ? topScrollMB.disable() : "";
			// ScrollTrigger.getById("topScrollMB").disable();
		});

		const menuOn = () => {
			store.dispatch('updateMenuFlg', true);
		};

		function hanyoFade(screenHeight, dom) {
			let opacity;

			// 计算 title4 到屏幕上方四分之一位置的距离
			const distanceToLeftQuarter = Math.abs(screenHeight / 10 - dom.top);
			// 计算 title4 到屏幕上方四分之一位置的距离
			const distanceToRightQuarter = Math.abs(screenHeight * 9 / 10 - dom.top);
			const quarterScreenHeight = screenHeight / 10;
			// 计算透明度
			if (dom.top >= quarterScreenHeight && dom.top <= screenHeight * 9 / 10) {
				opacity = 1;
			} else {
				const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenHeight);
				const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenHeight);
				opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
			}

			return opacity;
		}

		function topFade(screenHeight, dom) {
			let opacity;
			// 计算 title4 到屏幕上方四分之一位置的距离
			const distanceToLeftQuarter = Math.abs(screenHeight / 10 - dom.top);
			// 计算 title4 到屏幕上方四分之一位置的距离
			const distanceToRightQuarter = Math.abs(screenHeight * 9 / 10 - dom.top);
			const quarterScreenHeight = screenHeight / 10;
			// 计算透明度
			if (dom.top >= quarterScreenHeight && dom.top <= screenHeight * 9 / 10) {
				opacity = 1;
			} else {
				const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenHeight);
				const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenHeight);
				opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
			}

			// 当元素在上屏幕四分之一的时候保持透明度1
			if (dom.top <= quarterScreenHeight) {
				opacity = 1;
			}

			return opacity;
		}

		const router = useRouter();

		const toHealthS = () => {
			router.push('/products_healthS');
		};

		const toShopList = () => {
			router.push('/shopList');
		};

		const toAbout = () => {
			router.push('/about');
		};

		const toContact = () => {
			const subject = '';
			const body = '';
			// const mailtoString = `mailto:info@tkilyn.co.jp?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      const mailtoString = `mailto:info@tkilyn.co.jp`;
			window.location.href = mailtoString;
		};

		const toJitsu = () => {
			// closeBodyOverflow();
			router.push({ name: 'ShopList', hash: '#jitsu-shop' });
		};

		const toOnline = () => {
			// closeBodyOverflow();
			router.push({ name: 'ShopList', hash: '#online-shop' });
		};

    const toOther = () => {
      // closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#other-channels' });
    };

    const toProduct1 = () => {
      router.push({ name: 'Products_healthS'});
    };

    const toProduct2 = () => {
      router.push({ name: 'Products_beautyS'});
    };

    const toProduct3 = () => {
      router.push({ name: 'Products_skincareS'});
    };

		const amazonClick = (key) => {
			switch (key) {
				case 1:
					window.open('https://www.amazon.co.jp/BIOAGEN-PYRROVITAL-PRO-%E6%97%A5%E6%9C%AC%E8%A3%BD-NMN%E3%83%BBPQQ%E3%83%BBCoQ10%E8%A4%87%E6%95%B0%E9%85%8D%E5%90%88/dp/B0BD7BRP11', '_blank');
					break;
				case 2:
					window.open('https://www.amazon.co.jp/BIOAGEN-OVAVITAL-%E3%82%AA%E3%83%BC%E3%83%90%E3%83%90%E3%82%A4%E3%82%BF%E3%83%AB-%E5%A5%B3%E6%80%A7%E3%81%AE%E3%81%8A%E6%82%A9%E3%81%BF%E3%81%AB%E5%AF%84%E3%82%8A%E6%B7%BB%E3%81%86-%E6%A4%8D%E7%89%A9%E6%80%A7%E3%82%A4%E3%82%BD%E3%83%95%E3%83%A9%E3%83%9C%E3%83%B3/dp/B0BX62H548', '_blank');
					break;
				case 3:
					window.open('https://www.amazon.co.jp/BIOAGEN-PUROL-AGcode-Drink-%E6%97%A5%E6%9C%ACGMP%E5%B7%A5%E5%A0%B4%E8%A3%BD%E9%80%A0-%E6%9C%AC%E7%89%A9%E3%81%AE%E7%BE%8E%E8%82%8C%E3%81%9A%E3%81%A3%E3%81%A8%E8%A7%A6%E3%82%8C%E3%81%9F%E3%81%8F%E3%81%AA%E3%82%8B/dp/B0BD6GJC1L', '_blank');
					break;
				case 4:
					window.open('https://www.amazon.co.jp/BIOAGEN-PUROL%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88ME-%E6%97%A5%E6%9C%AC%E8%A3%BD-%E6%9C%AC%E7%89%A9%E3%82%92%E7%9F%A5%E3%82%8B%E5%A4%A7%E4%BA%BA%E3%81%AE%E3%81%9F%E3%82%81/dp/B0BGSJDQPJ', '_blank');
					break;
				case 5:
					window.open('https://www.amazon.co.jp/BIOAGEN-ENERGY-%E3%82%A8%E3%83%8A%E3%82%B8%E3%83%BC%E3%83%90%E3%82%A4%E3%82%BF%E3%83%AB-%E3%83%88%E3%83%BC%E3%82%BF%E3%83%AB%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88-%E3%82%B5%E3%83%97%E3%83%AA%E3%83%A1%E3%83%B3%E3%83%88/dp/B0BX1Z53ZL', '_blank');
					break;
				default:
					break;
			}
			console.log("okok")

		};
		return {
			one,
			two,
			title4,
			title2_kari,
			title4_fixed,
			title4_box,
			title5_fixed,
			title6,
			// title7,
			good1,
			good2,
			good3,
			good4,
			good5,
			// good7,
			// good8,
			svg,
			// svg2,
			// svg3,
			svg4,
			// svg5,
			svg6,
			// svg7,
			svg8,
			svg9,
			// svg10,
			container_mb,
			menuOn,
			toHealthS,
			toShopList,
			toAbout,
			toContact,
			toJitsu,
			toOnline,
      toOther,
      toProduct1,
      toProduct2,
      toProduct3,
			amazonClick
		};
	}
};

</script>

<style lang="scss" scoped>
@import '@/styles/mediaMB.scss';
</style>