<template>
  <div>
    <commonCover></commonCover>
    <div class="description">
      <div class="logo">
        <img src="@/assets/img/BIOAGE_LOGO.png" alt="">
      </div>
      <div class="menu" @click="menuOn">
        <img src="@/assets/img/menu.png" alt="">
      </div>
      
      <div>
        <video autoplay loop muted playsinline>
          <source src="@/assets/bgM.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="mark"></div>
        <div class="Baskerville-Old-Face title2" ref="title2">
          <div>No fading</div>
          <div>No aging</div>
          <div>But with BIOAGEN</div>
        </div>
      </div>
      <div class="title4_fixed BIZUDPMincho-Medium" ref="title4_fixed"></div>
      <div class="Baskerville-Old-Face title5_fixed" ref="title5_fixed"></div>
      <div class="good1" ref="good1">
        <img src="@/assets/img/logo2.png" alt="">
      </div>
    </div>

    <div class="container" ref="container">
      <img class="my_svg svg" src="@/assets/img/m/NMN.png" alt="" ref="svg" />
      <img class="my_svg svg3" src="@/assets/img/m/NMN.png" alt="" ref="svg3" />
      <img class="my_svg svg4" src="@/assets/img/m/PQQ.png" alt="" ref="svg4" />
      <img class="my_svg svg6" src="@/assets/img/m/NMN.png" alt="" ref="svg6" />
      <img class="my_svg svg7" src="@/assets/img/m/NMN.png" alt="" ref="svg7" />
      <img class="my_svg svg8" src="@/assets/img/m/PQQ.png" alt="" ref="svg8" />
      <img class="my_svg svg9" src="@/assets/img/m/NMN.png" alt="" ref="svg9" />
      <div class="bg-container" ref="bg_container"></div>
      <section id="one" class="panel bg bg1" ref="one"> 
        <div class="title4_box" ref="title4_box"></div>
        <div class="good21" ref="good21"></div>
        <div class="Baskerville-Old-Face title2_kari" ref="title2_kari"></div>
      </section>
      <section id="one2" class="panel bg bg1" ref="one2"> 
        <div class="good21" ref="good21"></div>
        <div class="Baskerville-Old-Face title2_kari" ref="title2_kari"></div>
        <div @click="amazonClick(1)" class="good2" ref="good2" style="cursor: pointer;">
          <img src="@/assets/img/v3/1_top.png" alt="">
        </div>
        <div class="title4 BIZUDPMincho-Medium" ref="title4"></div>
      </section>
      <section id="two" class="panel red bg2" ref="two">
        
        <div @click="amazonClick(3)" class="good3" ref="good3" style="cursor: pointer;">
          <img src="@/assets/img/m/3.png" alt="">
        </div>
        <div @click="amazonClick(2)" class="good31" ref="good31" style="cursor: pointer;">
          <img src="@/assets/img/v3/2_top.png" alt="">
        </div>
      </section>
      <section id="three" class="panel orange bg3">
        <div class="Baskerville-Old-Face title5" ref="title5"></div>
        <div class="title6 BIZUDPMincho-Medium" ref="title6"></div>
        <div @click="amazonClick(4)" class="good4" ref="good4" style="cursor: pointer;">
          <img src="@/assets/img/v3/3_top.png" alt="">
        </div>
        <div @click="amazonClick(5)" class="good5" ref="good5" style="cursor: pointer;">
          <img src="@/assets/img/v3/4_top.png" alt="">
        </div>
      </section>
      <section class="panel purple bg4">
        <div class="overlay-main-right">
          <div class="overlay-main-right-menu">
            <div>
              <div class="Baskerville-Old-Face menu-text menu-hover1"
                @mouseenter="() => mouseEnterMenu(1)"
                @mouseleave="() => mouseLeaveMenu(1)"
                @click="toTop"
              >Top
              </div>
              <div>
                <div class="Baskerville-Old-Face menu-text menu-hover2"
                     @mouseenter="() => mouseEnterMenu(2)"
                     @mouseleave="() => mouseLeaveMenu(2)"
                     @click="tohealthS"
                >Products</div>
                <div class="menu-text-sub2 Meiryo">
                  <div class="sub-product" @click="toProduct1">Health Support</div>
                  <div class="sub-product" @click="toProduct2">Beauty Support</div>
                  <div class="sub-product" @click="toProduct3">Skincare Series</div>
                </div>
              </div>
              <div>
                <div class="Baskerville-Old-Face menu-text menu-hover3"
                  @mouseenter="() => mouseEnterMenu(3)"
                  @mouseleave="() => mouseLeaveMenu(3)"
                  @click="toShopList"
                >Shop List</div>
                <div class="menu-text-sub Meiryo">
                  <div class="sub-shop hoverImg1"
                    @click="toJitsu"
                  >実店舗</div>
                  <div class="sub-shop hoverImg2"
                    @click="toOnline"
                  >オンライン店舗</div>
                  <div class="sub-shop hoverImg3"
                       @click="toOther"
                  >その他のチャネル</div>
                </div>
              </div>
              <div class="Baskerville-Old-Face menu-text menu-hover4"
                @mouseenter="() => mouseEnterMenu(4)"
                @mouseleave="() => mouseLeaveMenu(4)"
                @click="toAbout"
              >About</div>
              <div class="Baskerville-Old-Face menu-text menu-hover5"
                @mouseenter="() => mouseEnterMenu(5)"
                @mouseleave="() => mouseLeaveMenu(5)"
                @click="toContact"
              >Contact</div>
            </div>
          </div>
          <div class="share">
            <a href="https://instagram.com/bioagen" target="_blank">
              <div class="sns-area"
                @mouseenter="() => mouseEnterIns(1)"
                @mouseleave="() => mouseLeaveIns(1)"
              >
                <div class="sns-position">
                  <div class="sns icon-w1">
                    <img src="@/assets/img/ins-w.svg" alt="" />
                  </div>
                  <div class="sns icon-h1">
                    <img src="@/assets/img/ins-hover.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://lin.ee/HOIdxc2" target="_blank">
              <div class="line-area"
                @mouseenter="() => mouseEnterIns(2)"
                @mouseleave="() => mouseLeaveIns(2)"
              >
                <div class="sns-position">
                  <div class="sns icon-w2">
                    <img src="@/assets/img/v3/LINE_APP_W.png" alt="" />
                  </div>
                  <div class="sns icon-h2">
                    <img src="@/assets/img/v3/LINE_Brand_icon.png" alt="" />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://twitter.com/bioagen_japan?s=11&t=oOhE72Qfm-ezlPdR7DaIvQ" target="_blank">
              <div class="t-area"
                @mouseenter="() => mouseEnterIns(3)"
                @mouseleave="() => mouseLeaveIns(3)"
              >
                <div class="sns-position">
                  <div class="sns icon-w3">
                    <img src="@/assets/img/v3/Twitter-w.png" alt="" />
                  </div>
                  <div class="sns icon-h3">
                    <img src="@/assets/img/v3/Twitter-b.png" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      
    </div>
    
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'topPC',
  components: {
    commonCover
  },
  setup() {
    const one = ref(null);
    const two = ref(null);
    const title2 = ref(null);
    const title4 = ref(null);
    const title2_kari = ref(null);
    const title4_fixed = ref(null);
    const title4_box = ref(null)
    const title5 = ref(null);
    const title5_fixed = ref(null);
    const title6 = ref(null);
    const good1 = ref(null);
    const good2 = ref(null);
    const good21 = ref(null);
    const good3 = ref(null);
    const good31 = ref(null);
    const good4 = ref(null);
    const good5 = ref(null);
    const svg = ref(null);
    const svg2 = ref(null);
    const svg3 = ref(null);
    const svg4 = ref(null);
    const svg6 = ref(null);
    const svg7 = ref(null);
    const svg8 = ref(null);
    const svg9 = ref(null);
    const container = ref(null);
    const bg_container = ref(null);
    const store = useStore();

    onMounted(() => {
      if (document.documentElement.clientWidth > 440) {
        let screenWidth = window.innerWidth;
        // svg
        gsap.to(svg.value, { duration: 50, delay: 1, x: -1*screenWidth - 200 , rotation: 640, ease: "none", repeat: -1 });
        gsap.to(svg3.value, { duration: 50, delay: 20, x: -1*screenWidth - 200, rotation: 740, ease: "none", repeat: -1 });
        gsap.to(svg4.value, { duration: 50, delay: 4, x: -1*screenWidth - 200, rotation: 540, ease: "none", repeat: -1 });
        gsap.to(svg6.value, { duration: 50, delay: 40, x: -1*screenWidth - 200, rotation: 640, ease: "none", repeat: -1 });
        gsap.to(svg7.value, { duration: 50, delay: 20, x: -1*screenWidth - 200, rotation: 840, ease: "none", repeat: -1 });
        gsap.to(svg8.value, { duration: 50, delay: 40, x: -1*screenWidth - 200, rotation: 690, ease: "none", repeat: -1 });
        gsap.to(svg9.value, { duration: 50, delay: 10, x: -1*screenWidth - 200, rotation: 880, ease: "none", repeat: -1 });
        
        let sections = gsap.utils.toArray(".panel");

        ScrollTrigger.create({
          id: "topScroll1",
          trigger: "#one",
          scrub: 1,
          onUpdate: (self) => {
            if (one.value) {
              let oneDom = one.value.getBoundingClientRect();
              let title4Dom = title4.value.getBoundingClientRect();
              let good2Dom = good2.value.getBoundingClientRect();
              let title4FixedDom = title4_fixed.value.getBoundingClientRect();
              let title4BoxDom = title4_box.value.getBoundingClientRect();

              gsap.to(title2.value, {
                opacity: title4FixedDom.left > title4Dom.left ? 0 : 1,
                duration: 1,
              });
              gsap.to(title4_fixed.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.5,
              });
              gsap.to(good1.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(good2.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
                delay: 0.5
              });
              gsap.to(good21.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
                delay: 0.5
              });
              // svg
              gsap.to(svg.value, {
                opacity: ((oneDom.left <= 0 || oneDom) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg2.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg3.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg4.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg6.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg7.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg8.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg9.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
            }
          },
        });

        gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none",
          scrollTrigger: {
            id: "topScroll2",
            trigger: ".container",
            pin: true,
            scrub: 1,
            snap: 1 / (sections.length - 1),
            end: "+=" + screenWidth,
            onUpdate: (self) => {
              if (one.value) {
                let oneDom = one.value.getBoundingClientRect();
                let title4Dom = title4.value.getBoundingClientRect();
                let title5Dom = title5.value.getBoundingClientRect();
                let title5FixedDom = title5_fixed.value.getBoundingClientRect();
                let title4FixedDom = title4_fixed.value.getBoundingClientRect();
                let title6Dom = title6.value.getBoundingClientRect();
                let good2Dom = good2.value.getBoundingClientRect();
                let good3Dom = good3.value.getBoundingClientRect();
                let good31Dom = good31.value.getBoundingClientRect();
                let good4Dom = good4.value.getBoundingClientRect();
                let good5Dom = good5.value.getBoundingClientRect();

                let screenWidth = window.innerWidth;
                console.log("self",self.direction)
                console.log("self2",self.progress)
                // bgC
                gsap.to(".bg-container", {
                  xPercent: self.progress,
                  // duration: 0.5,
                });

                gsap.to(title4.value, {
                  opacity: title4FixedDom.left > title4Dom.left ? hanyoFade(screenWidth, title4Dom) : 0,
                  duration: 0.5,
                });
                gsap.to(title2_kari.value, {
                  opacity: title4FixedDom.left > title4Dom.left ? hanyoFade(screenWidth, title4Dom) : 0,
                  duration: 0.5,
                });
                gsap.to(title2.value, {
                  opacity: title4FixedDom.left > good2Dom.left ? 0 : 1,
                  duration: 1,
                });
                gsap.to(title4_fixed.value, {
                  opacity: title4FixedDom.left > title4Dom.left || (oneDom.left > -20 && oneDom.left < 0) ? 0 : 1,
                  duration: 0.5,
                });
                gsap.to(title5.value, {
                  opacity: title5FixedDom.left > title5Dom.left ? 0 : migiFade(screenWidth, title5Dom),
                  duration: 0.5,
                });
                gsap.to(title5_fixed.value, {
                  zIndex: title5FixedDom.left > title5Dom.left ? 2 : -2,
                  duration: 0,
                });
                gsap.to(title5_fixed.value, {
                  opacity: title5FixedDom.left > title5Dom.left ? 1 : 0,
                  duration: 0.5,
                });
                gsap.to(title6.value, {
                  opacity: hanyoFade(screenWidth, title6Dom),
                  duration: 0.5,
                });
                gsap.to(good1.value, {
                  opacity: title4FixedDom.left > good2Dom.left || (oneDom.left > -20 && oneDom.left < 0) ? 0 : 1,
                  duration: 0.5,
                });
                gsap.to(good3.value, {
                  opacity: hanyoFade(screenWidth, good3Dom),
                  duration: 1,
                  delay: 0.3
                });
                gsap.to(good31.value, {
                  opacity: hanyoFade(screenWidth, good31Dom),
                  duration: 1,
                  delay: 0.3
                });
                gsap.to(good4.value, {
                  opacity: hanyoFade(screenWidth, good4Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(good5.value, {
                  opacity: hanyoFade(screenWidth, good5Dom),
                  duration: 0.8,
                  delay: 0.5
                });
              }
            },
          } 
        });
      }
    });

    onUnmounted(() => {
      let topScroll1 = ScrollTrigger.getById("topScroll1");
      topScroll1?topScroll1.disable():"";
      let topScroll2 = ScrollTrigger.getById("topScroll2");
      topScroll2?topScroll2.disable():"";
    });
    
    const mouseEnterMenu = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".menu-hover" + index, {
          scale: 1.1,
          textShadow: '0px 0px 10px #0e0f1f90',
          duration: 0.3,
        });
      }
    };

    const mouseLeaveMenu = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".menu-hover" + index, {
          scale: 1,
          textShadow: 'none',
          duration: 0.3,
        });
      }
    };

    const mouseEnterIns = (index) => {
      gsap.to(".icon-w" + index, {
        opacity: 0,
        duration: 0.5,
      });
      gsap.to(".icon-h" + index, {
        opacity: 1,
        duration: 0.5,
      });
    };

    const mouseLeaveIns = (index) => {
      gsap.to(".icon-w" + index, {
        opacity: 1,
        duration: 0.5,
      });
      gsap.to(".icon-h" + index, {
        opacity: 0,
        duration: 0.5,
      });
    };

    const toTop = () => {
      // closeBodyOverflow();
      router.push('/');
    };

    const tohealthS = () => {
      // closeBodyOverflow();
      router.push('/products_healthS');
    };

    const toAbout = () => {
      // closeBodyOverflow();
      router.push('/about');
    };

    const toShopList = () => {
      // closeBodyOverflow();
      router.push('/shopList');
    };

    const toJitsu = () => {
      // closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#jitsu-shop' });
    };

    const toOnline = () => {
      // closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#online-shop' });
    };

    const toOther = () => {
      // closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#other-channels' });
    };

    const toProduct1 = () => {
      router.push({ name: 'Products_healthS'});
    };

    const toProduct2 = () => {
      router.push({ name: 'Products_beautyS'});
    };

    const toProduct3 = () => {
      router.push({ name: 'Products_skincareS'});
    };

    const closeBodyOverflow = () => {
      // gsap.to(".close-overlay", {
      //   display: "none",
      //   duration: 0,
      // });
      // store.dispatch('updateMenuFlg', false);
      if (path === "/") {
        console.log("path",path)
        switch (document.documentElement.clientWidth <= 440) {
          case true:
            document.body.style.overflow = '';
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = '';
            break;
          case false:
            document.body.style.overflow = '';
            document.body.style.overflowX = '';
            document.body.style.overflowY = 'hidden';
            break;
          default:
            break;
        }
      } else {
        console.log("path2",path)
        document.body.style.overflow = '';
        document.body.style.overflowX = '';
        document.body.style.overflowY = '';
      }
    };

    const menuOn = () => {
      store.dispatch('updateMenuFlg', true);
    };
    
    function hanyoFade(screenWidth, dom) {
      let opacity;

      // 计算 title4 到屏幕左侧四分之一位置的距离
      const distanceToLeftQuarter = Math.abs(screenWidth / 10 - dom.left);
      // 计算 title4 到屏幕右侧四分之一位置的距离
      const distanceToRightQuarter = Math.abs(screenWidth * 9 / 10 - dom.left);
      const quarterScreenWidth = screenWidth / 10;
      // 计算透明度
      if (dom.left >= quarterScreenWidth && dom.left <= screenWidth * 9 / 10) {
        opacity = 1;
      } else {
        const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenWidth);
        const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenWidth);
        opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
      }

      return opacity;
    }

    function migiFade(screenWidth, dom) {
      let opacity;
      // 计算 title4 到屏幕左侧四分之一位置的距离
      const distanceToLeftQuarter = Math.abs(screenWidth / 10 - dom.left);
      // 计算 title4 到屏幕右侧四分之一位置的距离
      const distanceToRightQuarter = Math.abs(screenWidth * 9 / 10 - dom.left);
      const quarterScreenWidth = screenWidth / 10;
      // 计算透明度
      if (dom.left >= quarterScreenWidth && dom.left <= screenWidth * 9 / 10) {
        opacity = 1;
      } else {
        const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenWidth);
        const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenWidth);
        opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
      }

      // 当元素在左屏幕四分之一的时候保持透明度1
      if (dom.left <= quarterScreenWidth) {
        opacity = 1;
      }

      return opacity;
    }

    const router = useRouter();

    const toHealthS = () => {
      router.push('/products_healthS');
    };

    const toContact = () => {
      const subject = '';
      const body = '';
      // const mailtoString = `mailto:info@tkilyn.co.jp?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      const mailtoString = `mailto:info@tkilyn.co.jp`;
      window.location.href = mailtoString;
    };

    const amazonClick = (key) => {
      switch (key) {
        case 1:
          window.open('https://www.amazon.co.jp/BIOAGEN-PYRROVITAL-PRO-%E6%97%A5%E6%9C%AC%E8%A3%BD-NMN%E3%83%BBPQQ%E3%83%BBCoQ10%E8%A4%87%E6%95%B0%E9%85%8D%E5%90%88/dp/B0BD7BRP11', '_blank');
          break;
        case 2:
          window.open('https://www.amazon.co.jp/BIOAGEN-OVAVITAL-%E3%82%AA%E3%83%BC%E3%83%90%E3%83%90%E3%82%A4%E3%82%BF%E3%83%AB-%E5%A5%B3%E6%80%A7%E3%81%AE%E3%81%8A%E6%82%A9%E3%81%BF%E3%81%AB%E5%AF%84%E3%82%8A%E6%B7%BB%E3%81%86-%E6%A4%8D%E7%89%A9%E6%80%A7%E3%82%A4%E3%82%BD%E3%83%95%E3%83%A9%E3%83%9C%E3%83%B3/dp/B0BX62H548', '_blank');
          break;
        case 3:
          window.open('https://www.amazon.co.jp/BIOAGEN-PUROL-AGcode-Drink-%E6%97%A5%E6%9C%ACGMP%E5%B7%A5%E5%A0%B4%E8%A3%BD%E9%80%A0-%E6%9C%AC%E7%89%A9%E3%81%AE%E7%BE%8E%E8%82%8C%E3%81%9A%E3%81%A3%E3%81%A8%E8%A7%A6%E3%82%8C%E3%81%9F%E3%81%8F%E3%81%AA%E3%82%8B/dp/B0BD6GJC1L', '_blank');
          break;
        case 4:
          window.open('https://www.amazon.co.jp/BIOAGEN-PUROL%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88ME-%E6%97%A5%E6%9C%AC%E8%A3%BD-%E6%9C%AC%E7%89%A9%E3%82%92%E7%9F%A5%E3%82%8B%E5%A4%A7%E4%BA%BA%E3%81%AE%E3%81%9F%E3%82%81/dp/B0BGSJDQPJ', '_blank');
          break;
        case 5:
          window.open('https://www.amazon.co.jp/BIOAGEN-ENERGY-%E3%82%A8%E3%83%8A%E3%82%B8%E3%83%BC%E3%83%90%E3%82%A4%E3%82%BF%E3%83%AB-%E3%83%88%E3%83%BC%E3%82%BF%E3%83%AB%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88-%E3%82%B5%E3%83%97%E3%83%AA%E3%83%A1%E3%83%B3%E3%83%88/dp/B0BX1Z53ZL', '_blank');
          break;
        default:
          break;
      }
      console.log("okok")
      
    };

    return {
      one,
      two,
      title2,
      title4,
      title2_kari,
      title4_fixed,
      title4_box,
      title5,
      title5_fixed,
      title6,
      // title7,
      good1,
      good2,
      good21,
      good3,
      good31,
      good4,
      good5,
      // good6,
      // good7,
      // good8,
      svg,
      svg2,
      svg3,
      svg4,
      // svg5,
      svg6,
      svg7,
      svg8,
      svg9,
      // svg10,
      container,
      menuOn,
      toHealthS,
      toShopList,
      toAbout,
      toContact,
      mouseEnterMenu,
      mouseLeaveMenu,
      mouseEnterIns,
      mouseLeaveIns,
      toTop,
      tohealthS,
      // toAbout,
      // toShopList,
      // toContact,
      toJitsu,
      toOnline,
      toOther,
      toProduct1,
      toProduct2,
      toProduct3,
      amazonClick
    };
  }
};

</script>

<style lang="scss" scoped>

@media screen and (min-width: 1260px) and (max-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (max-height: 899px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (max-height: 699px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (min-width: 1261px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (min-width: 1580px) and (max-height: 900px) {
	@import '@/styles/mediaTopPC_minh700_maxh899.scss';
}
@media screen and (min-width: 1580px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_minw1580_minh900.scss';
}
</style>