<template>
  <div class="goods">
    <div class="goods-title bahnschrift-Light-Condensde">Popular Items</div>
    <div class="goods-main">
      <div class="goods-textBox GoPr6N" @mouseleave="() => mouseLeaveCover()">
        <div class="goods-cover cover"></div>
        <div class="goods-text text1">
          <div class="goods-text-title bahnschrift-Light-Condensde">PYRROVITAL PRO</div>
          <div class="goods-text-p">
            「カラダの変化を感じ始めた時」が、<br />
            &nbsp;&nbsp;&nbsp;&nbsp;エイジングケア*のベストタイミング。<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;いつも、いつまでも<br />
            &nbsp;&nbsp;&nbsp;&nbsp;自分らしく輝く毎日のために。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 10px;">*年齢に応じたケアのこと</span>
          </div>
          <div class="goods-text-img gti1">
            <img src="@/assets/img/PYRROVITAL-PRO.png" alt="" />
          </div>
        </div>
        <div class="goods-text text2">
          <div class="goods-text-title bahnschrift-Light-Condensde">ENERGY VITAL</div>
          <div class="goods-text-p">
            情熱とパワーは、あなたをもっと強くする。<br />
            エナジーに満ち溢れた毎日を。<br /><br />
            心も身体も若さを失わないあなたへ。<br />
          </div>
          <div class="goods-text-img gti2">
            <img src="@/assets/img/ENERGY.png" alt="" />
          </div>
        </div>
        <div class="goods-text text3">
          <div class="goods-text-title bahnschrift-Light-Condensde">PUROL AGcode Drink</div>
          <div class="goods-text-p">
            真の美しさは内面からにじみ出るもの。<br /><br />
            弾む 輝く 透き通る<br />
            思わず触れたくなる「肌」<br /><br />
            あなたの肌で体感してください。<br />
          </div>
          <div class="goods-text-img goods-text-img-v gti3">
            <img src="@/assets/img/purol1.png" alt="" />
          </div>
        </div>
        <div class="goods-text text4">
          <div class="goods-text-title bahnschrift-Light-Condensde">PUROL</div>
          <div class="goods-text-p">
            澄み切った「白」<br />
            みずみずしい透輝肌<br /><br />
            カラダのすみずみまで届く美しさを。<br />
          </div>
          <div class="goods-text-img goods-text-img-v2 gti4">
            <img src="@/assets/img/purol.png" alt="" />
          </div> 
        </div>
      </div>
      <div class="goods-container">
        <div class="goods-group">
          <div class="goods-img img1" @mouseenter="() => mouseEnterGoodImg(1)">
            <img src="@/assets/img/m/b2.png" alt="" />
          </div>
          <div class="goods-img img2" @mouseenter="() => mouseEnterGoodImg(2)">
            <img src="@/assets/img/m/b1.png" alt="" />
          </div>
        </div>
        <div class="goods-group">
          <div class="goods-img img3" @mouseenter="() => mouseEnterGoodImg(3)">
            <img src="@/assets/img/m/b3.png" alt="" />
          </div>
          <div class="goods-img img4" @mouseenter="() => mouseEnterGoodImg(4)">
            <img src="@/assets/img/m/b4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="awards" v-if="source=='about'">
    <div class="awards_title">Awards</div>
    <div class="item_title">2022年</div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/awards1.jpg" alt="">
          </p>
          <p class="name bold">BEST COSME AWARD</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/pro1.png" alt="">
          </p>
          <p class="name">PUROLAGcodeDrink</p>
        </li>
      </ul>
      <ul class="desc">
        <p>大人の品格美容マガジン</p>
        <p>キャッチコピーは＂一、肌、二、髪、三、気品“。</p>
        <p>肌と髪にポイントを置き、大人目線の美容法をわかりやすく紹介する「大人の品格美容マガジン」です。</p>
        <p>美容は顔だけにあらず！ファッションと美容の両面で素敵な大人の女性を目指す女性たちを応援しています。</p>
        <p>長年美容担当を経てきたベテラン編集者たちが厳選した信頼ある商品を厳選して紹介し読者から高い信頼を得ています。</p>
      </ul>
    </div>
    <div class="item_title">2023年</div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/awards2.jpg" alt="">
          </p>
          <p class="name bold">MYベストコスメ2023</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/pro1.png" alt="">
          </p>
          <p class="name">PUROLAGcodeDrink</p>
        </li>
      </ul>
      <ul class="desc">
        <p>リンネルは創刊から一貫して“自分らしく、心地よく、本当にくつろげる暮らしとおしゃれ”を提案してい ます。</p>
        <p>“心地よい暮らしとおしゃれ”の小さな芽は、“暮らし系”という大きな輪になって広がっています。</p>
        <p>流行やブームをやみくもに追いかけるのではなく、ていねいに日々を暮らすことで、満たされた時間を増 やす。</p>
      </ul>
    </div>
    <div class="awards_line"></div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/awards3.jpg" alt="">
          </p>
          <p class="name bold">MYベストコスメ2023</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/pro3.png" alt="">
          </p>
          <p class="name">PYRROVITAL PRO</p>
        </li>
      </ul>
      <ul class="desc">
        <p>GLOWは2010年10月に創刊し、自由で新しい40代女性像を提唱し「40代女子」「大人女子」ブームを生みました。</p>
        <p>創刊以来、ファッションでは脱コンサバの「大人カジュアル」、美容では加齢を否定しない「ポジティブ美容」と、新しい概念で 40代女性を応援し市場を盛り上げています。 さらに2018年からは、朝日新聞社と共同で「Aging Gracefully」プロジェクトをスタート。</p>
      </ul>
    </div>
    <div class="awards_line"></div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/awards4.jpg" alt="">
          </p>
          <p class="name bold">男性経営者の８０％が推奨すると回答</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/pro4.png" alt="">
          </p>
          <p class="name">Energy Vital</p>
        </li>
      </ul>
      <ul class="desc">
        <p class="bold">30代以上男性経営者の推奨獲得！</p>
        <p style="margin-bottom: 15px;">【ハイスペックビジネスマンの働き方】ストレスを感じている方は7割以上！心身の健康のためにサプリメントを摂る？</p>
        <p style="margin-bottom: 5px;">【調査期間】2023年6月13日（火）〜2023年6月14日（水）</p>
        <p style="margin-bottom: 5px;">【調査方法】インターネット調査</p>
        <p>【調査対象】30代以上の男性経営者トキリン株式会社は、全国の経営者、医師、弁護士（※男性、35歳以上）の方を対象に、「ハイスぺックビジネスマンの働き方」に関する調査を実施しました。</p>
      </ul>
    </div>

    <div class="item_title">2024年</div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/awards5.jpg" alt="">
          </p>
          <p class="name bold">Best Glitter Award</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/pro5.jpg" alt="">
          </p>
          <p class="name">スキンケアシリーズ</p>
        </li>
      </ul>
      <ul class="desc">
        <p>「Girls Happy Style Sellection 2024」（日本のテレビ番組『Girls Happy Style』選定）の『Glitter Award』を受賞</p>
      </ul>
    </div>

    <div class="awards_line"></div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/awards.jpg" alt="">
          </p>
          <p class="name bold">OMOTENASHI<br>SELECTION</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/pro0.jpg" alt="">
          </p>
          <p class="name">PYRROVITAL PRO & OVAVITAL</p>
        </li>
      </ul>
      <ul class="desc">
        <p>おもてなしセレクションでは、「OMOTENASHI」=「使い手の事を思いやる心遣い、こだわりが感じられるものか」の他、利用しやすさ、共感性、価格妥当性、独自性、持続可能性、国際通用性の6項目の評価基準で審査・評価をしています。</p>
      </ul>
    </div>

    <div class="awards_line"></div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/awards02.jpg" alt="">
          </p>
          <p class="name bold">MAQUIA<br>ONLINE<br>イチオシ認定</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/pro1.jpg" alt="">
          </p>
          <p class="name">FACIAL MASK</p>
        </li>
      </ul>
      <ul class="desc">
        <p class="bold" style="font-size: 15px;">MAQUIA ONLINE（パック＆マスク部門） イチオシ認定</p>
      </ul>
    </div>

    <div class="awards_line"></div>
    <div class="item_div">
      <ul class="image">
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/awards01.jpg" alt="">
          </p>
          <p class="name bold">ESTHE SELECTION<br>金賞</p>
        </li>
        <li class="">
          <p class="img">
            <img src="@/assets/img/awards/t/pro1.jpg" alt="">
          </p>
          <p class="name">FACIAL MASK</p>
        </li>
      </ul>
      <ul class="desc">
        <p class="bold" style="font-size: 15px;">エステセレクション2014　金賞</p>
      </ul>
    </div>

  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "commonGoods",
  props: {
    source: {
      type: String,
      default: 'null'
    }
  },
  setup() {
    const mouseEnterGoodImg = (index) => {
      gsap.killTweensOf(".goods-img");
      gsap.killTweensOf(".goods-text");
      gsap.killTweensOf(".goods-textBox");
      gsap.killTweensOf(".img" + index);
      gsap.killTweensOf(".cover");
      gsap.killTweensOf(".text" + index);
      gsap.killTweensOf(".gti" + index);
      gsap.to(".goods-text-img", { opacity: 0, duration: 0 });
      gsap.to(".goods-textBox", { zIndex: 5, duration: 0 });
      gsap.to(".goods-img", { opacity: 0.1, duration: 0 });
      gsap.to(".img" + index, { opacity: 1, duration: 0,
        onComplete: () => {
          gsap.to(".cover", { opacity: 1, duration: 0.5 });  
          gsap.to(".text" + index, { opacity: 1, duration: 0.5 });
          gsap.to(".gti" + index, { opacity: 0.7, duration: 0.5 });
        },
      });
    };

    const mouseLeaveCover = () => {
      gsap.to(".goods-img", { opacity: 1, duration: 0 });
      gsap.to(".goods-text", { opacity: 0, duration: 0.3 });
      gsap.to(".cover", { opacity: 0, duration: 0.3,
        onComplete: () => {
          gsap.to(".goods-textBox", { zIndex: -5, duration: 0 });
        },
      });
    };

    return {
      mouseEnterGoodImg,
      mouseLeaveCover,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 840px) {
  @import "@/styles/mediaCommonG_min840.scss";
}
@media screen and (max-width: 840px) {
  @import "@/styles/mediaCommonG_max840.scss";
}
</style>