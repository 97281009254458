<template>
  <div class="header">
    <div class="header-main header-title">
      <div class="menu" @click="menuOn" >
        <img src="@/assets/img/menu.png" :style="imgMode!=='0'?'filter: invert(1);':'filter: invert(0);'" alt="">
      </div>
      <div class="title bahnschrift-Light-Condensde" :style="imgMode!=='0'?'color: #222':'#fff'">{{ title }}</div>
      <div v-if="mode!=='0'" :style="imgMode!=='0'?'color: #222':''" class="title bahnschrift-Light-Condensde mark">></div>
      <div class="title-sub" v-if="mode=='1'" :style="imgMode!=='0'?'color: #222':'#fff'">
        <div class="title2 BIZUDPGothic-Regular">{{ title2 }}</div>
        <div class="subTitle BIZUDPGothic-Regular">{{ subTitle }}</div>
      </div>  
      <div class="title-sub" v-if="mode=='2'" :style="imgMode!=='0'?'color: #222':'#fff'">
        <div class="title2 BIZUDPGothic-Regular">{{ title2 }}</div>
        <div class="subTitle BIZUDPGothic-Regular">{{ subTitle }}</div>
      </div>
    </div>
    <!-- <div class="header-main header-subTitle">
      <div class="subTitle BIZUDPGothic-Regular">{{ subTitle }}</div>
    </div> -->
    <img v-if="imgMode=='0'" class="img-bg" :src="imgPath" alt="">
    <img v-if="imgMode=='1'" class="img-bg1" :src="imgPath" alt="">
    <img v-if="imgMode=='2'" class="img-bg2" :src="imgPath" alt="">

    <div v-if="imgMode=='0'" class="main-text GoPr6N"> 
      <div class="main-text1">{{headerText}}</div>
      <div class="main-text-sub">
        <div>
          <div v-for="(t,i) in headerText2" :key="i">
            <div class="main-text2">{{t}}</div>
          </div>
        </div>
        <div class="main-text-m">
          <div v-for="(t2,i2) in headerText3" :key="i2">
            <div class="main-text3">{{t2}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
export default {
  name: "commonHeader",
  props: {
    title: {
      type: String,
      default: 'TITLE'
    },
    title2: {
      type: String,
      default: 'TITLE2'
    },
    subTitle: {
      type: String,
      default: 'SUBTITLE'
    },
    imgPath: {
      type: String,
      default: ''
    },
    headerText: {
      type: String,
      default: ''
    },
    headerText2: {
      type: String[''],
      default: ['']
    },
    headerText3: {
      type: String[''],
      default: ['']
    },
    mode: {
      type: String,
      default: '0'
    },
    imgMode: {
      type: String,
      default: '0'
    },
  },
  setup() {
    const store = useStore();

    const menuOn = () => {
      console.log("on")
      store.dispatch('updateMenuFlg', true);
    };

    return {
      menuOn
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1300px) {
	@import '@/styles/mediaCommonH_min1300.scss';
}
@media screen and (max-width: 1300px) and (min-width: 840px) {
	@import '@/styles/mediaCommonH_max1299.scss';
}
@media screen and (max-width: 840px) {
	@import '@/styles/mediaCommonH_max840.scss';
}
@media screen and (max-width: 500px) {
  .img-bg {left: 40%;}
}
</style>