<template>
  <div class="commonBg">
    <commonHeader :title="phTitle" :subTitle="phSubTitle" :imgPath="imgPath" :imgMode="'0'" :headerText="headerText" :headerText2="headerText2" :headerText3="headerText3"></commonHeader>
    <commonCover></commonCover>
    <!-- video -->
    <!-- <button @click="toggleMute" class="videoBtn">ミュートの切り替え</button> -->
    <div class="video-area">
      <video ref="myVideo" autoplay :muted="!isMuted" loop playsinline>
        <source src="@/assets/bgM.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="banner">
      <img class="banner-bg" src="@/assets/img/v3/001.jpg" alt="">
      <div class="text">
        <img class="banner-logo" src="@/assets/img/BIOAGE_LOGO2.png" alt="">
        <div class="GoPr6N banner-text">
          <div>BIOAGENは、100年以上の歴史を持ち</div>
          <div>高品質なサプリメントの研究開発にも力を入れる</div>
          <div>美吉野製薬株式会社と協力し</div>
          <div>2018年に設立したエイジングケアブランドです。</div>
        </div>
      </div>
    </div>

    <div class="img-text GoPr6N">
      <div class="img-text-img">
        <img src="@/assets/img/v3/4.png" alt="">
        <img src="@/assets/img/v3/2.png" alt="">
        <img src="@/assets/img/v3/3.png" alt="">
        <img src="@/assets/img/v3/1.png" alt="">
      </div>
      <div class="img-text-text">
        <div class="img-text-text-title">東京・静岡・奈良・岐阜・中国上海</div>
        <div class="img-text-text-c">
          <div>研究開発、サプライチェーン管理のスペシャリストが協働し、健康食品及びスキンケア製品の研究開発、製造、販売を行っています。</div>
          <div class="mT">研究開発者は、臨床医、神経内科医、および薬学のバックグラウンドを持ち、国内外の多くの大学や研究機関と連携しています。</div>
        </div>
      </div>
    </div>
    <div style="height: 50px;width: 100%" id="about_scroll_mark"></div>

    <div class="point">
      <div class="point-img">
        <img src="@/assets/img/v3/11.jpg" alt="">
      </div>
      <div class="point-title">
        <div>
          <div class="bahnschrift-Light-Condensde point-title-s">Development</div>
          <div class="point-title-b point-mt">開発</div>
        </div>
      </div>
      <div class="point-text GoPr6N">
        <div>
          <div>百年以上の歴史を持つ製薬会社をはじめ</div>
          <div>医学・薬学などの専門家と協働</div>
        </div>
      </div>
    </div>

    <div class="point cG">
      <div class="point-img">
        <img src="@/assets/img/v3/12.png" alt="">
      </div>
      <div class="point-title">
        <div>
          <div class="bahnschrift-Light-Condensde point-title-s">Ingredients</div>
          <div class="point-title-b point-mt">原料</div>
        </div>
      </div>
      <div class="point-text GoPr6N">
        <div>
          <div>原産地・加工地において分析試験で</div>
          <div>安全性が確認された原料のみ採用</div>
        </div>
      </div>
    </div>

    <div class="point">
      <div class="point-img">
        <img src="@/assets/img/v3/13.png" alt="">
      </div>
      <div class="point-title">
        <div>
          <div class="bahnschrift-Light-Condensde point-title-s">Manufactured</div>
          <div class="bahnschrift-Light-Condensde point-title-s">In Japan</div>
          <div class="point-title-b point-mt">国内</div>
          <div class="point-title-b">製造</div>
        </div>
      </div>
      <div class="point-text GoPr6N">
        <div>
          <div>日本国内の製薬工場</div>
          <div>またはGMP認定食品製造工場にて生産</div>
        </div>
      </div>
    </div>

    <div class="about-fixtext bahnschrift-Light-Condensde" ref="fixed_text">About us</div>
    
    <commonGoods :source="'about'"></commonGoods>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import commonHeader from '../components/commonHeader.vue';
import commonGoods from '../components/commonGoods.vue';
import commonFooter from '../components/commonFooter.vue';
import commonProduct from '../components/commonProduct.vue';
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "About",
  components: {
    commonHeader,
    commonGoods,
    commonFooter,
    commonProduct,
    commonCover
  },
  setup() {
    const store = useStore();
    
    const phTitle = ref("About");
    const phSubTitle = ref("");
    const imgPath = ref(require('@/assets/img/TOPBg.jpg'));
    const headerText = ref("心豊かに 健康に 美しく");
    const headerText2 = ref([
      "笑顔溢れる未来のためにも",
      "今の一瞬を大切にしたい。"
    ]);
    const headerText3 = ref([
      "生命科学とテクノロジーで",
      "あなたに寄り添う",
      "次世代のエイジングケア体験を。"
    ]);
    const fixed_text = ref(null);
    const route = useRoute();
    const path = route.path;
    const myVideo = ref(null);
    //commonProductの内容設定
    const proText = ref([
      "澄み切った「白」",
      "みずみずしい透輝肌",
      "カラダのすみずみまで届く美しさを。",
    ]);
    const mauText = ref([
      "私たちはいつまでも過去に自慢できる、光り輝くあなたでいて欲しい心からそう願っています。さあ、殻を破り新しい自分に会いに行こう。",
      "自分「らしさ」とは何か新しい自分に出会う旅に出かけよう。",
    ]);
    const proImg = ref(require("@/assets/img/kao.jpg"));
    const proIdx = ref("1");
    const txtAddi = ref(null)
    const isMuted = ref(true);

    const toggleMute = () => {
      if (myVideo.value.muted === false) {
        myVideo.value.muted = true;
      } else {
        myVideo.value.muted = false;
      }
    };

    watch(() => store.state.isAudioOn, (newValue) => {
      console.log("newValue1111",newValue)
      isMuted.value = newValue;
    });

    onMounted(async () => {
      console.log("111",isMuted.value)
      console.log("111",store.state.isAudioOn)
      isMuted.value = store.state.isAudioOn;
      console.log("222",isMuted.value)
      // await nextTick();
      if (path === '/about'){
        console.log("about");
        // myVideo.value = document.querySelector('video');

        const video_area = document.querySelectorAll(".video-area");
        const banner = document.querySelectorAll(".banner");
        const img_text_img = document.querySelectorAll(".img-text-img");
        const img_text_text_title = document.querySelectorAll(".img-text-text-title");
        const img_text_text_c = document.querySelectorAll(".img-text-text-c");
        const point = document.querySelectorAll(".point");
        
        video_area.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        banner.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        img_text_img.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        img_text_text_title.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        img_text_text_c.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        point.forEach((v) => {
          gsap.from(v, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: v,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        gsap.set(fixed_text.value, { autoAlpha: 0 })

        let tween = gsap.to(fixed_text.value, { autoAlpha: 1, paused: true, duration: 0.5 })

        ScrollTrigger.create({
          trigger: window.innerWidth >= 840 ? '.video-area' : '#about_scroll_mark',
          start: 'bottom center',
          endTrigger: '.cG',
          end: 'bottom center',
          onEnter: ({progress, direction, isActive}) => { tween[direction === 1 ? "play" : "reverse"]() },
          onLeave: ({progress, direction, isActive}) => { tween[direction === 1 ? "reverse" : "play"]() },
          onEnterBack: ({progress, direction, isActive}) => { tween[direction === 1 ? "reverse" : "play"]() },
          onLeaveBack: ({progress, direction, isActive}) => { tween[direction === 1 ? "play" : "reverse"]() },
          scrub: true
        })
      }
    });
    
    return {
      isMuted,
      phTitle,
      phSubTitle,
      imgPath,
      headerText,
      headerText2,
      headerText3,
      fixed_text,
      proText,
      mauText,
      proImg,
      proIdx,
      txtAddi,
      myVideo,
      toggleMute,
      // video_area,
      // banner,
      // img_text_img,
      // img_text_text_title,
      // img_text_text_c,
      // point,
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaAbout_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 840px) {
  @import "@/styles/mediaAbout_max1029.scss";
}
@media screen and (max-width: 840px) {
  @import "@/styles/mediaAbout_max840.scss";
}
@media screen and (max-width: 537px) {
  .img-text {padding-top: 140px;}
}
</style>