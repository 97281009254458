<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-contents">
        <div class="footer-contents-top">
          <div class="flex-bottom">
            <div class="flex-menu bahnschrift-Light-Condensde">
              <div @click="toHome">Home</div>
              <div @click="toHealthS">Products</div>
              <div @click="toShopList">Shop List</div>
              <div @click="toAbout">About</div>
              <div @click="toContact">Contact</div>
            </div>
          </div>
        </div>
        <div class="footer-contents-bottom BIZUDPGothic-Regular">
          <div class="footer-logo" @click="toHome">
            <img class="svg-logo" src="@/assets/svg/BIOAGENLOGO.svg" alt="" ref="svg" />
          </div>
          <div class="footer-contents-bottom-sns">
            <a href="https://instagram.com/bioagen" target="_blank">
              <img class="svg1-sns" src="@/assets/img/instagram_g.png" alt="" />
            </a>
            <a href="https://lin.ee/HOIdxc2" target="_blank">
              <img class="svg2-sns" src="@/assets/img/LINE_APP.png" alt="" />
            </a>
            <a href="https://twitter.com/bioagen_japan?s=11&t=oOhE72Qfm-ezlPdR7DaIvQ" target="_blank">
              <img class="svg3-sns" src="@/assets/img/v3/Twitter-g.png" alt="" />
            </a>
          </div>
          <div class="footer-contents-bottom-text">
            <div>お客様窓口</div>
            <div>03-4588-5488</div>
            <div>　</div>
            <div>Copyright© TKILYN CO., LTD. All Right Reserved.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "",
  setup() {
    const router = useRouter();

    const toHome = () => {
      router.push("/");
    };

    const toHealthS = () => {
      router.push("/products_healthS");
    };

    const toShopList = () => {
      router.push("/shopList");
    };

    const toAbout = () => {
      router.push("/about");
    };

    const toContact = () => {
      const subject = '';
      const body = '';
      // const mailtoString = `mailto:info@tkilyn.co.jp?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      const mailtoString = `mailto:info@tkilyn.co.jp`;
      window.location.href = mailtoString;
    };

    return {
      toHome,
      toHealthS,
      toShopList,
      toAbout,
      toContact,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaCommonF_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 840px) {
  @import "@/styles/mediaCommonF_max1029.scss";
}
@media screen and (max-width: 840px) {
  @import "@/styles/mediaCommonF_max840.scss";
}
</style>