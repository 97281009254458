<template>
  <div>
    <commonCover></commonCover>
    <div class="description">
      <div class="logo">
        <img src="@/assets/img/BIOAGE_LOGO.png" alt="">
      </div>
      <div class="menu" @click="menuOn">
        <img src="@/assets/img/menu.png" alt="">
      </div>
      <div class="pyrrovital1">
        <img src="@/assets/img/PYRROVITAL1.png" alt="">
      </div>
      <div class="pyrrovital2">
        <img src="@/assets/img/PYRROVITAL2.png" alt="">
      </div>
      <img class="my_svg svg" src="@/assets/svg/my_svg.svg" alt="" ref="svg" />
      <img class="my_svg svg2" src="@/assets/svg/my_svg2.svg" alt="" ref="svg2" />
      <img class="my_svg svg3" src="@/assets/svg/my_svg.svg" alt="" ref="svg3" />
      <img class="my_svg svg4" src="@/assets/svg/my_svg2.svg" alt="" ref="svg4" />
      <img class="my_svg svg5" src="@/assets/svg/my_svg2.svg" alt="" ref="svg5" />
      <img class="my_svg svg6" src="@/assets/svg/my_svg2.svg" alt="" ref="svg6" />
      <img class="my_svg svg7" src="@/assets/svg/my_svg.svg" alt="" ref="svg7" />
      <img class="my_svg svg8" src="@/assets/svg/my_svg.svg" alt="" ref="svg8" />
      <img class="my_svg svg9" src="@/assets/svg/my_svg2.svg" alt="" ref="svg9" />
      <img class="my_svg svg10" src="@/assets/svg/my_svg.svg" alt="" ref="svg10" />
      <div>
        <video autoplay loop muted playsinline>
          <source src="@/assets/bg.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="mark"></div>
        <div class="title BIZUDPGothic-Bold">自分らしく輝く毎日のために</div>
        <div class="Baskerville-Old-Face title2" ref="title2">
          <div>No fading</div>
          <div>No aging</div>
          <div>But with BIOAGEN</div>
        </div>
        <div class="title3 BIZUDPMincho-Medium">
          <div>いつまでも過去に自慢できる、光り輝くあなたでいてほしい。</div>
          <div>幸福感に満ちたあなたは強く、美しい。</div>
          <div>さあ、殻を破り新しい自分に会いに行こう。</div>
        </div>
      </div>
      <div class="title4_fixed BIZUDPMincho-Medium" ref="title4_fixed">
        <div>雑踏の中でも思わず目で追ってしまう</div>
        <div>その一瞬の美しさも逃さない。</div>
        <div>それはキレイの目印</div>
      </div>
      <div class="Baskerville-Old-Face title5_fixed" ref="title5_fixed">
        <div @click="toHealthS">Products</div>
        <div @click="toShopList">Shop List</div>
        <div @click="toAbout">About</div>
        <div @click="toContact">Contact</div>
      </div>
      <div class="good1" ref="good1">
        <img src="@/assets/img/good1.png" alt="">
      </div>
    </div>

    <div class="container" ref="container">
      <section id="one" class="panel bg bg1" ref="one">
        <div class="title4_box" ref="title4_box"></div>
        <div class="good2" ref="good2">
          <img src="@/assets/img/good2.png" alt="">
        </div>
      </section>
      <section id="two" class="panel red bg2" ref="two">
        <div class="Baskerville-Old-Face title2_kari" ref="title2_kari">
          <div>No fading</div>
          <div>No aging</div>
          <div>But with BIOAGEN</div>
        </div>
        <div class="title4 BIZUDPMincho-Medium" ref="title4">
          <div>雑踏の中でも思わず目で追ってしまう</div>
          <div>その一瞬の美しさも逃さない。</div>
          <div>それはキレイの目印</div>
        </div>
        <div class="good3" ref="good3">
          <img src="@/assets/img/good3.png" alt="">
        </div>
      </section>
      <section id="three" class="panel orange bg3">
        <div class="Baskerville-Old-Face title5" ref="title5">
          <div @click="toHealthS">Products</div>
          <div @click="toShopList">Shop List</div>
          <div @click="toAbout">About</div>
          <div @click="toContact">Contact</div>
        </div>
        <div class="title6 BIZUDPMincho-Medium" ref="title6">
          <div>最初の一歩、それはきっと大きな一歩。</div>
          <div>ワクワクが待っている。</div>
          <div>新しい事にチャレンジしたくなる。</div>
          <div>その先に新しい世界が広がっている。</div>
        </div>
        <div class="good4" ref="good4">
          <img src="@/assets/img/good4.png" alt="">
        </div>
        <div class="good5" ref="good5">
          <img src="@/assets/img/good5.png" alt="">
        </div>
      </section>
      <section class="panel purple bg4">
        <div class="good6" ref="good6">
          <img src="@/assets/img/good6.png" alt="">
        </div>
        <div class="good7" ref="good7">
          <img src="@/assets/img/good7.png" alt="">
        </div>
        <div class="imgText">
          <div class="good8" ref="good8">
            <img src="@/assets/img/good8.png" alt="">
          </div>
          <div class="title7 BIZUDPMincho-Medium" ref="title7">
            <div>世界はモノゴトで溢れている</div>
            <div>でも、わたしたちの願いはただひとつ。</div>
          </div>
        </div>
      </section>
      
    </div>
    
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'topPC',
  components: {
    commonCover
  },
  setup() {
    const one = ref(null);
    const two = ref(null);
    const title2 = ref(null);
    const title4 = ref(null);
    const title2_kari = ref(null);
    const title4_fixed = ref(null);
    const title4_box = ref(null)
    const title5 = ref(null);
    const title5_fixed = ref(null);
    const title6 = ref(null);
    const title7 = ref(null);
    const good1 = ref(null);
    const good2 = ref(null);
    const good3 = ref(null);
    const good4 = ref(null);
    const good5 = ref(null);
    const good6 = ref(null);
    const good7 = ref(null);
    const good8 = ref(null);
    const svg = ref(null);
    const svg2 = ref(null);
    const svg3 = ref(null);
    const svg4 = ref(null);
    const svg5 = ref(null);
    const svg6 = ref(null);
    const svg7 = ref(null);
    const svg8 = ref(null);
    const svg9 = ref(null);
    const svg10 = ref(null);
    const container = ref(null);
    const store = useStore();

    onMounted(() => {
      if (document.documentElement.clientWidth > 440) {
        let screenWidth = window.innerWidth;
        // svg
        gsap.to(svg.value, { duration: 50, delay: 5, x: -1*screenWidth, rotation: 1440, ease: "none", repeat: -1 });
        gsap.to(svg2.value, { duration: 50, delay: 18, x: -1*screenWidth, rotation: 1040, ease: "none", repeat: -1 });
        gsap.to(svg3.value, { duration: 50, delay: 26, x: -1*screenWidth, rotation: 1140, ease: "none", repeat: -1 });
        gsap.to(svg4.value, { duration: 50, delay: 35, x: -1*screenWidth, rotation: 1340, ease: "none", repeat: -1 });
        gsap.to(svg5.value, { duration: 50, delay: 1, x: -1*screenWidth, rotation: 1240, ease: "none", repeat: -1 });
        gsap.to(svg6.value, { duration: 50, delay: 26, x: -1*screenWidth, rotation: 1940, ease: "none", repeat: -1 });
        gsap.to(svg7.value, { duration: 50, delay: 4, x: -1*screenWidth, rotation: 1840, ease: "none", repeat: -1 });
        gsap.to(svg8.value, { duration: 50, delay: 20, x: -1*screenWidth, rotation: 1740, ease: "none", repeat: -1 });
        gsap.to(svg9.value, { duration: 50, delay: 30, x: -1*screenWidth, rotation: 1640, ease: "none", repeat: -1 });
        gsap.to(svg10.value, { duration: 50, delay: 5, x: -1*screenWidth, rotation: 1540, ease: "none", repeat: -1 });
        
        let sections = gsap.utils.toArray(".panel");

        ScrollTrigger.create({
          id: "topScroll1",
          trigger: "#one",
          scrub: 1,
          onUpdate: (self) => {
            if (one.value) {
              let oneDom = one.value.getBoundingClientRect();
              let title4Dom = title4.value.getBoundingClientRect();
              let title4FixedDom = title4_fixed.value.getBoundingClientRect();
              let title4BoxDom = title4_box.value.getBoundingClientRect();

              gsap.to(title2.value, {
                opacity: title4FixedDom.left > title4Dom.left ? 0 : 1,
                duration: 0.5,
              });
              gsap.to(title4_fixed.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.5,
              });
              gsap.to(good1.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(good2.value, {
                opacity: title4FixedDom.left > title4Dom.left || ((oneDom.left > -20 && oneDom.left <= 0) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
                delay: 0.5
              });
              // svg
              gsap.to(svg.value, {
                opacity: ((oneDom.left <= 0 || oneDom) && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg2.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg3.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg4.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg5.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg6.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg7.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg8.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg9.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
              gsap.to(svg10.value, {
                opacity: (oneDom.left <= 0 && title4FixedDom.top < title4BoxDom.top) ? 0 : 1,
                duration: 0.8,
              });
            }
          },
        });
      
        gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none",
          scrollTrigger: {
            id: "topScroll2",
            trigger: ".container",
            pin: true,
            scrub: 1,
            snap: 1 / (sections.length - 1),
            end: "+=" + screenWidth,
            onUpdate: (self) => {
              if (one.value) {
                let oneDom = one.value.getBoundingClientRect();
                let title4Dom = title4.value.getBoundingClientRect();
                let title5Dom = title5.value.getBoundingClientRect();
                let title5FixedDom = title5_fixed.value.getBoundingClientRect();
                let title4FixedDom = title4_fixed.value.getBoundingClientRect();
                let title6Dom = title6.value.getBoundingClientRect();
                let title7Dom = title7.value.getBoundingClientRect();
                let good3Dom = good3.value.getBoundingClientRect();
                let good4Dom = good4.value.getBoundingClientRect();
                let good5Dom = good5.value.getBoundingClientRect();
                let good6Dom = good6.value.getBoundingClientRect();
                let good7Dom = good7.value.getBoundingClientRect();
                let good8Dom = good8.value.getBoundingClientRect();

                let screenWidth = window.innerWidth;

                gsap.to(title4.value, {
                  opacity: title4FixedDom.left > title4Dom.left ? hanyoFade(screenWidth, title4Dom) : 0,
                  duration: 0.5,
                });
                gsap.to(title2_kari.value, {
                  opacity: title4FixedDom.left > title4Dom.left ? hanyoFade(screenWidth, title4Dom) : 0,
                  duration: 0.5,
                });
                gsap.to(title2.value, {
                  opacity: title4FixedDom.left > title4Dom.left ? 0 : 1,
                  duration: 0.5,
                });
                gsap.to(title4_fixed.value, {
                  opacity: title4FixedDom.left > title4Dom.left || (oneDom.left > -20 && oneDom.left < 0) ? 0 : 1,
                  duration: 0.5,
                });
                gsap.to(title5.value, {
                  opacity: title5FixedDom.left > title5Dom.left ? 0 : migiFade(screenWidth, title5Dom),
                  duration: 0.5,
                });
                gsap.to(title5_fixed.value, {
                  zIndex: title5FixedDom.left > title5Dom.left ? 2 : -2,
                  duration: 0,
                });
                gsap.to(title5_fixed.value, {
                  opacity: title5FixedDom.left > title5Dom.left ? 1 : 0,
                  duration: 0.5,
                });
                gsap.to(title6.value, {
                  opacity: hanyoFade(screenWidth, title6Dom),
                  duration: 0.5,
                });
                gsap.to(good1.value, {
                  opacity: title4FixedDom.left > title4Dom.left || (oneDom.left > -20 && oneDom.left < 0) ? 0 : 1,
                  duration: 0.8,
                });
                gsap.to(good3.value, {
                  opacity: hanyoFade(screenWidth, good3Dom),
                  duration: 1,
                  delay: 0.3
                });
                gsap.to(good4.value, {
                  opacity: hanyoFade(screenWidth, good4Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(good5.value, {
                  opacity: hanyoFade(screenWidth, good5Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(good6.value, {
                  opacity: migiFade(screenWidth, good6Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(good7.value, {
                  opacity: hanyoFade(screenWidth, good7Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(good8.value, {
                  opacity: hanyoFade(screenWidth, good8Dom),
                  duration: 0.8,
                  delay: 0.5
                });
                gsap.to(title7.value, {
                  opacity: hanyoFade(screenWidth, title7Dom),
                  duration: 1,
                  delay: 0.5
                });
              }
            },
          } 
        });
      }
    });

    onUnmounted(() => {
      let topScroll1 = ScrollTrigger.getById("topScroll1");
      topScroll1?topScroll1.disable():"";
      // ScrollTrigger.getById("topScroll1").disable();
      let topScroll2 = ScrollTrigger.getById("topScroll2");
      topScroll2?topScroll2.disable():"";
      // ScrollTrigger.getById("topScroll2").disable();
    });
    
    const menuOn = () => {
      store.dispatch('updateMenuFlg', true);
    };

    function hanyoFade(screenWidth, dom) {
      let opacity;

      // 计算 title4 到屏幕左侧四分之一位置的距离
      const distanceToLeftQuarter = Math.abs(screenWidth / 4 - dom.left);
      // 计算 title4 到屏幕右侧四分之一位置的距离
      const distanceToRightQuarter = Math.abs(screenWidth * 3 / 4 - dom.left);
      const quarterScreenWidth = screenWidth / 4;
      // 计算透明度
      if (dom.left >= quarterScreenWidth && dom.left <= screenWidth * 3 / 4) {
        opacity = 1;
      } else {
        const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenWidth);
        const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenWidth);
        opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
      }

      return opacity;
    }

    function migiFade(screenWidth, dom) {
      let opacity;
      // 计算 title4 到屏幕左侧四分之一位置的距离
      const distanceToLeftQuarter = Math.abs(screenWidth / 4 - dom.left);
      // 计算 title4 到屏幕右侧四分之一位置的距离
      const distanceToRightQuarter = Math.abs(screenWidth * 3 / 4 - dom.left);
      const quarterScreenWidth = screenWidth / 4;
      // 计算透明度
      if (dom.left >= quarterScreenWidth && dom.left <= screenWidth * 3 / 4) {
        opacity = 1;
      } else {
        const opacityToLeftQuarter = Math.min(1, 1 - distanceToLeftQuarter / quarterScreenWidth);
        const opacityToRightQuarter = Math.min(1, 1 - distanceToRightQuarter / quarterScreenWidth);
        opacity = Math.max(opacityToLeftQuarter, opacityToRightQuarter);
      }

      // 当元素在左屏幕四分之一的时候保持透明度1
      if (dom.left <= quarterScreenWidth) {
        opacity = 1;
      }

      return opacity;
    }

    const router = useRouter();

    const toHealthS = () => {
      router.push('/products_healthS');
    };

    const toShopList = () => {
      router.push('/shopList');
    };

    const toAbout = () => {
      router.push('/about');
    };

    const toContact = () => {
      const subject = '';
      const body = '';
      const mailtoString = `mailto:info@bio-agen.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoString;
    };

    return {
      one,
      two,
      title2,
      title4,
      title2_kari,
      title4_fixed,
      title4_box,
      title5,
      title5_fixed,
      title6,
      title7,
      good1,
      good2,
      good3,
      good4,
      good5,
      good6,
      good7,
      good8,
      svg,
      svg2,
      svg3,
      svg4,
      svg5,
      svg6,
      svg7,
      svg8,
      svg9,
      svg10,
      container,
      menuOn,
      toHealthS,
      toShopList,
      toAbout,
      toContact,
    };
  }
};

</script>

<style lang="scss" scoped>

@media screen and (min-width: 1260px) and (max-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (max-height: 899px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (max-height: 699px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (max-width: 1260px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (min-width: 1261px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_maxw1260_maxh699.scss';
}
@media screen and (min-width: 1580px) and (max-height: 900px) {
	@import '@/styles/mediaTopPC_minh700_maxh899.scss';
}
@media screen and (min-width: 1580px) and (min-height: 900px) {
	@import '@/styles/mediaTopPC_minw1580_minh900.scss';
}
</style>