<template>
  <div>
    <div
      class="productBox"
      @mouseenter="() => mouseEnter(proIdx)"
      @mouseleave="() => mouseLeave(proIdx)"
    >
      <div class="product-cover" :class="'cover' + proIdx"></div>
      <div v-if="mode=='0'" class="product-image" :class="'image' + proIdx">
        <img class="product-img" :class="'pimg' + proIdx" :src="proImg" alt="" />
      </div>
      <div v-if="mode=='1'" class="product-image2" :class="'image' + proIdx">
        <img class="product-img" :class="'pimg' + proIdx" :src="proImg" alt="" />
      </div>
      <div class="product-text1 GoPr6N" :class="'text1' + proIdx">
        <div>
          <div v-for="(text1, index) in proText" :key="index">
            <div v-html="text1" class="product-text1-p" :class="'text1p' + proIdx">
            </div>
          </div>
        </div>
      </div>
      <div class="product-text2 GoPr6N" :class="'text2' + proIdx">
        <div>
          <div v-for="(txt, index) in mauText" :key="index">
            <div v-html="txt" class="product-text2-p" :class="'text2p' + proIdx">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="priceShow" class="textBox GoPr6N">
      <div>
        <div v-for="(text, index) in mauText" :key="index">
          <div v-html="text" class="textBox-p" :class="'textAp' + proIdx"></div>
        </div>
      </div>
    </div>
    <div class="textBox GoPr6N">
      <div>
        <div v-for="(text, index) in txtAddi" :key="index">
          <div v-html="text" class="textBox-p" :class="'textAp' + proIdx"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "commonProduct",
  props: {
    proText: {
      type: Array,
      default: "TEXT",
    },
    mauText: {
      type: Array,
      default: "TEXT",
    },
    proImg: {
      type: String,
      default: "@/assets/img/all.png",
    },
    proIdx: {
      type: String,
      default: "0",
    },
    txtAddi: {
      type: Array,
      default: "TEXT",
    },
    mode: {
      type: String,
      default: "0",
    },
  },
  setup(props) {
    const priceShow = ref(document.documentElement.clientWidth < 870);
    const onResize = () => {
      priceShow.value = document.documentElement.clientWidth < 855;
    };
    onMounted(() => {
      window.addEventListener("resize", onResize);
      const covers = document.querySelectorAll(".cover" + props.proIdx);
      const images = document.querySelectorAll(".image" + props.proIdx);
      const text1s = document.querySelectorAll(".text1p" + props.proIdx);
      const text2s = document.querySelectorAll(".text2p" + props.proIdx);
      const textas = document.querySelectorAll(".textAp" + props.proIdx);

      covers.forEach((image) => {
        gsap.from(image, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: image,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      images.forEach((image) => {
        gsap.from(image, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: image,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      text1s.forEach((text) => {
        gsap.from(text, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: text,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      text2s.forEach((text) => {
        gsap.from(text, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: text,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      textas.forEach((text) => {
        gsap.from(text, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: text,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });
    });

    const mouseEnter = (proIdx) => {
      if (document.documentElement.clientWidth > 853) {
        gsap.killTweensOf(".text1" + proIdx);
        gsap.killTweensOf(".pimg" + proIdx);
        gsap.killTweensOf(".text2" + proIdx);
        gsap.to(".text1" + proIdx, {
          opacity: 0,
          duration: 0,
          onComplete: () => {
            props.mode=='0'?gsap.to(".pimg" + proIdx, { width: "50%", duration: 0.5 }):
                            gsap.to(".pimg" + proIdx, { width: "80%", duration: 0.5 });
            gsap.to(".cover" + proIdx, {
              opacity: 1,
              width: "100%",
              duration: 0.5,
            });
            gsap.to(".text2" + proIdx, { opacity: 1, duration: 0.5 });
          },
        });
      }
    };

    const mouseLeave = (proIdx) => {
      if (document.documentElement.clientWidth > 853) {
        gsap.killTweensOf(".text1" + proIdx);
        gsap.killTweensOf(".pimg" + proIdx);
        gsap.killTweensOf(".text2" + proIdx);
        props.mode=='0'?gsap.to(".pimg" + proIdx, { width: "42%", duration: 0.5 }):
                        gsap.to(".pimg" + proIdx, { width: "70%", duration: 0.5 });
        gsap.to(".cover" + proIdx, { opacity: 0, width: "50%", duration: 0.5 });
        gsap.to(".text2" + proIdx, {
          opacity: 0,
          duration: 0.2,
          onComplete: () => {
            gsap.to(".text1" + proIdx, { opacity: 1, duration: 0 });
          },
        });
      }
    };

    return {
      mouseEnter,
      mouseLeave,
      priceShow
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaCommonP_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 870px) {
  @import "@/styles/mediaCommonP_max1029.scss";
}
@media screen and (max-width: 870px) {
  @import "@/styles/mediaCommonP_max870.scss";
}
</style>