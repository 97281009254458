<template>
  <div>
    <div class="commonBg" ref="commonBg">
      <commonHeader
          :title="phTitle"
          title2="Skincare"
          :subTitle="phSubTitle"
          :imgPath="imgPath"
          :imgMode="'0'"
          :mode="2"
      ></commonHeader>
      <commonCover></commonCover>
      <div
          class="proBea-fixtext fixtext bahnschrift-Light-Condensde" ref="fixtext">
        Skincare Series
      </div>

      <div class="proBea-main">
        <div class="proBea-container">

          <div class="proBea-item1">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>笑顔弾む美しさを</span>
              </div>
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 bahnschrift-Light-Condensde">
                  <span>ESSENCE</span>
                </div>
                <div class="title2-right start proBea-title-text2 Meiryo-Bold">
                  <span>エッセンス</span>
                </div>
              </div>
            </div>
            <commonProduct
                :proText="proText1"
                :mauText="mauText1"
                :proImg="proImg1"
                :proIdx="proIdx1"
                :txtAddi="txtAddi1"
                :mode="0"
            ></commonProduct>
          </div>

          <div class="line"></div>

          <div class="proBea-item1">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>時を超えた美しさを</span>
              </div>
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 bahnschrift-Light-Condensde">
                  <span>EYE CREAM</span>
                </div>
                <div class="title2-right proBea-title-text2 Meiryo-Bold">
                  <span>アイクリーム</span>
                </div>
              </div>
            </div>
            <commonProduct
                :proText="proText2"
                :mauText="mauText2"
                :proImg="proImg2"
                :proIdx="proIdx2"
                :txtAddi="txtAddi2"
                :mode="1"
            ></commonProduct>
          </div>

          <div class="line line2"></div>

          <div class="proBea-item1">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>今日と未来の、あなたのためのケア</span>
              </div>
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 bahnschrift-Light-Condensde">
                  <span>FACIAL CREAM</span>
                </div>
                <div class="title2-right proBea-title-text2 Meiryo-Bold">
                  <span>フェイスクリーム</span>
                </div>
              </div>
            </div>
            <commonProduct
                :proText="proText3"
                :mauText="mauText3"
                :proImg="proImg3"
                :proIdx="proIdx3"
                :txtAddi="txtAddi3"
                :mode="1"
            ></commonProduct>
          </div>

          <div class="line line3"></div>

          <div class="proBea-item3">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>この一枚で広がる、新しい美への可能性</span>
              </div>
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 bahnschrift-Light-Condensde">
                  <span>FACIAL MASK</span>
                </div>
                <div class="title2-right proBea-title-text2 Meiryo-Bold">
                  <span>フェイスパック</span>
                </div>
              </div>
            </div>
            <commonProduct
                :proText="proText4"
                :mauText="mauText4"
                :proImg="proImg4"
                :proIdx="proIdx4"
                :txtAddi="txtAddi4"
                :mode="1"
            ></commonProduct>
          </div>

        </div>
      </div>

      <div class="logo-area">
        <div class="normal-logo logoEnd" ref="logoEnd">
          <img class="proBea-logo2" src="@/assets/img/BIOAGE_LOGO.png" alt="" @click="toHome">
        </div>
      </div>
    </div>
    <commonMenu style="z-index: 10"></commonMenu>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import commonHeader from "../components/commonHeader.vue";
import commonFooter from "../components/commonFooter.vue";
import commonMenu from "../components/commonMenu.vue";
import commonProduct from "../components/commonProduct.vue";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Products_skincareS",
  components: {
    commonHeader,
    commonFooter,
    commonMenu,
    commonProduct,
    commonCover
  },
  setup() {
    const phTitle = ref("Products");
    const phSubTitle = ref("スキンケア");
    const imgPath = ref(require("@/assets/img/m/SKINCARE_TOP.png"));
    const commonBg = ref(null);
    const fixtext = ref(null);
    const imgStart1 = ref(null);
    const imgEnd2 = ref(null);
    const flogo = ref(null);
    const logoEnd = ref(null);
    const route = useRoute();
    const path = route.path;

    const proText1 = ref([
      "未体験のハリ、満ちる",
      "贅沢な美容液で至福の時間を"
    ]);
    const mauText1 = ref([
      "複合ペプチド、エルゴチオネイン、スーパープロテオグリカンなどの複数の美容成分が、コラーゲンやヒアルロン酸の働きをサポート。ハリ・弾力を与え、キメの整った滑らかな肌に導きます。",
    ]);
    const proImg1 = ref(require("@/assets/img/ssgoods/Skincare_ESSENCE.png"));
    const proIdx1 = ref("1");
    const txtAddi1 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥17,600（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>35mL / 1.18 FL.OZ.</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[美容成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>複合ペプチド、エルゴチオネイン、スーパープロテオグリカン</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[ご使用いただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア＊に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングサインが気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・乾燥が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・ハリ、弾力が気になる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[使用方法]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>適量を手にとり、両手のひらを合わせ、手のひらの間でエッセンスをあたためてください。温まったエッセンスを顔全体にまんべんなく塗布し、やさしくマッサージしながら、なじませてください。</div>",
    ])

    const proText2 = ref([
      "ハリ、弾力あふれる",
      "目元に宿る自信と輝き",
    ]);
    const mauText2 = ref([
      "複合ペプチド、アセチルヘキサペプチド- 8 、エルゴチオネイン、乳酸桿菌/ワサビ根﻿発酵エキス、乳酸桿菌発酵液などの複数の美容成分が、年齢肌にアプローチ。目元を明るくハリのある印象に導きます。",
    ]);
    const proImg2 = ref(require("@/assets/img/ssgoods/Skincare_EYE CREAM.png"));
    const proIdx2 = ref("2");
    const txtAddi2 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥14,960（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>18g / 0.63 OZ.</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[美容成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>複合ペプチド、アセチルヘキサペプチド- 8、エルゴチオネイン、乳酸桿菌/ワサビ根発酵エキス、乳酸桿菌発酵液</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[ご使用いただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングサインが気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・乾燥が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・ハリ、弾力、シワが気になる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[使用方法]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>適量を指にとり、目元に塗布します。均一にのばし、指先またはマッサージスティックを使って目頭から目じりにかけてマッサージし、なじませてください。</div>",
    ])

    const proText3 = ref([
      "歳と共に美しさを重ねる",
      "素肌をさらなる高みへ",
      "ハリと弾力のあるなめらかな肌に",
    ]);
    const mauText3 = ref([
      "複合ペプチド、アセチルテトラペプチド - 2、アセチルヘキサペプチド- 8 、エルゴチオネイン、海洋微生物発酵エキスなどの複数の美容成分が、年齢肌にアプローチ。肌のキメを整えて、ハリのあるツヤ肌へと導きます。",
    ]);
    const proImg3 = ref(require("@/assets/img/ssgoods/Skincare_FACIAL_CREAM.png"));
    const proIdx3 = ref("3");
    const txtAddi3 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥20,460（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>45g / 1.58 OZ.</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[美容成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>複合ペプチド、アセチルテトラペプチド- 2、アセチルヘキサペプチド- 8、エルゴチオネイン、海洋微生物発酵エキス</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[ご使用いただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングサインが気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・乾燥が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・ハリ、弾力、たるみが気になる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[使用方法]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>・スパチュラで適量を手の甲にとリ、指で顔へ点置きします。</div>",
      "<div style='line-height: 1.2;font-size: 16px'>・内側から外側に向かって手のひらでやさしく円を描くように、顔全体にのばします。</div>",
      "<div style='line-height: 1.2;font-size: 16px'>・最後に両手のひらであごからほほを包み、やさしく数回ハンドプレスし、よくなじませてください。</div>",
    ])

    const proText4 = ref([
      "肌を包む幸せ",
      "私を満たす集中ケア",
    ]);
    const mauText4 = ref([
      "複合ペプチドをはじめ6種類以上のペプチド配合で、ヒアルロン酸＋コラーゲンの働きをサポート。うるおって透明感のある*なめらかな肌に導きます。日常的なお肌のケアから特別な日のための集中ケアまで、とっておきのフェイスパックをご体感ください。",
      "*保湿による",
    ]);
    const proImg4 = ref(require("@/assets/img/ssgoods/Skincare_Facial_mask.png"));
    const proIdx4 = ref("4");
    const txtAddi4 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥6,160（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'> 35ml / 1.18 FL.OZ. * 5 枚</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[美容成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>カルノシン、複合植物エキス、エルゴチオネイン、アセチルオクタペプチド-3、アセチルヘキサペプチド-8、アセチルヘプタペプチド-4、レスベラトロール、アセチルテトラペプチド-2、複合ペプチド</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[ご使用いただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・しっかり集中ケアを行いたい方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングサインが気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・乾燥が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・ハリ、弾力が気になる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[使用方法]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>① 洗顔後、フェイスパックを取り出し、最初に目元の位置を合わせてから、唇の位置を合わせます。</div>",
      "<div style='line-height: 1.2;font-size: 16px'>② パックを肌にのせて、手で軽く押さえ密着させてください。</div>",
      "<div style='line-height: 1.2;font-size: 16px'>③ 約15分～20分後、顔からパックを取り、優しくなじませてください。、吸収された後で、ぬるま湯で流してください。\n</div>",
      "<div style='line-height: 1.2;font-size: 16px'>・毎日もしくは2日に一度ご使用ください。</div>",
    ])

    onMounted(async () => {
      if (path === "/products_skincareS") {
        console.log("products_skincareS");

        const titles = document.querySelectorAll(".proBea-title-text1");
        const subTitles = document.querySelectorAll(".proBea-title-text2");
        const bigImages = document.querySelectorAll(".proBea-item-image1");
        const lines = document.querySelectorAll(".line");

        titles.forEach((title) => {
          gsap.from(title, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: title,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        subTitles.forEach((subtitle) => {
          gsap.from(subtitle, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: subtitle,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        bigImages.forEach((image) => {
          gsap.from(image, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: image,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        lines.forEach((line) => {
          gsap.from(line, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: line,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        gsap.to(fixtext.value, {
          opacity: 0.8,
          scrollTrigger: {
            trigger: '.start', // imgStart1.value
            start: "top center",
            end: "center center",
            scrub: 1,
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });

        gsap.to(flogo.value, {
          opacity: 0,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });

        gsap.to(logoEnd.value, {
          opacity: 1,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });
      }
    });

    const mouseEnterBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImage" + index, {
          y: -10,
          // boxShadow: "",
          duration: 0.5,
        });
      }
    };

    const mouseLeaveBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImage" + index, {
          y: 0,
          // boxShadow: "6px 6px 14px #e6e6e6",
          duration: 0.5,
        });
      }
    };

    const router = useRouter();

    const toHome = () => {
      router.push('/');
    };

    return {
      phTitle,
      phSubTitle,
      imgPath,
      commonBg,
      fixtext,
      imgStart1,
      imgEnd2,
      flogo,
      logoEnd,

      proText1,
      mauText1,
      proImg1,
      proIdx1,
      txtAddi1,

      proText2,
      mauText2,
      proImg2,
      proIdx2,
      txtAddi2,

      proText3,
      mauText3,
      proImg3,
      proIdx3,
      txtAddi3,

      proText4,
      mauText4,
      proImg4,
      proIdx4,
      txtAddi4,

      mouseEnterBigImg,
      mouseLeaveBigImg,
      toHome,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaSkincareS_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 870px) {
  @import "@/styles/mediaSkincareS_max1029.scss";
}
@media screen and (max-width: 870px) {
  @import "@/styles/mediaSkincareS_max870.scss";
}
</style>