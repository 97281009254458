<template>
  <div>
    <div class="commonBg" ref="commonBg">
      <commonHeader
        :title="phTitle"
        :subTitle="phSubTitle"
        :imgPath="imgPath"
        :imgMode="'2'"
      ></commonHeader>
      <commonCover></commonCover>
      <div
        class="shopList-fixtext fixtext bahnschrift-Light-Condensde"
        ref="fixtext"
      >
        Shop List
      </div>

      <div class="shopl-main">
        <!-- <div class="tip Meiryo">
          <div>ご購入はお近くの店舗にお立ち寄りください。</div>
          <div>
            商品の在庫につきましては、お近くの店舗へお問い合わせください。
          </div>
          <div>
            掲載店舗以外でのお取り寄せ、販売はしておりません。ご了承ください。
          </div>
        </div> -->
        <!---------------------------------------------------------------------->
        <div
          id="jitsu-shop"
          class="good-img bigImg1 imgStart1"
          @mouseenter="() => mouseEnterBigImg(1)"
          @mouseleave="() => mouseLeaveBigImg(1)"
          ref="imgStart1"
        >
          <div class="good-img-mark-text">
            <div>
              <div class="bahnschrift-Light-Condensde good-img-mark-text-title">
                <div>Physical</div>
                <div>Store</div>
              </div>
              <div class="good-img-mark-text-subTitle Meiryo">実店舗</div>
            </div>
          </div>
          <div class="good-img-mark"></div>
          <div class="good-img-n">
            <img src="@/assets/img/m/shop1.jpg" alt="" />
          </div>
        </div>
        <div class="shop">
          <!-- <div class="shop-mark"></div> -->
          <div class="shop-imgs">
            <div
              class="logoM shop-img  shop-img1 minw"
              @mouseenter="() => mouseEnterShopImg(1)"
              @mouseleave="() => mouseLeaveShopImg(1)"
            >
              <img src="@/assets/img/m/shop2.png" alt="" />
            </div>
            <div
              class="shop-img shop-img2 minw"
              @mouseenter="() => mouseEnterShopImg(2)"
              @mouseleave="() => mouseLeaveShopImg(2)"
            >
              <img src="@/assets/img/m/shop3.png" alt="" />
            </div>
            <div
              class="shop-img shop-img3 minw"
              @mouseenter="() => mouseEnterShopImg(3)"
              @mouseleave="() => mouseLeaveShopImg(3)"
            >
              <img src="@/assets/img/m/shop4.png" alt="" />
            </div>
            <div class="shop-img shop-img4"></div>
            <div
              class="shop-img psy-shop bgCFFF"
              @mouseenter="() => mouseEnterPhyShop()"
              @mouseleave="() => mouseLeavePhyShop()"
            >
              <div class="psy-shop-arrow">
                <div class="bahnschrift-Light-Condensde">Visit us</div>
                <div><img src="@/assets/img/right.svg" alt="" /></div>
              </div>
              <div class="psy-shop-cover"></div>
              <div class="psy-shop-text">
                <div class="psy-shop-qrcode">
                  <img src="@/assets/img/QR-code-w.png" alt="" />
                </div>
                <div class="psy-shop-address GoPr6N">
                  <p>目白店<br />〒171-0031<br />東京都豊島区目白三丁目13番5-1階<br />TEL：03-4588-5488<br><span>※店舗電話番号のため、商品に関するお問い合わせ以外は、メールにて資料をお送りください。ご理解のほど、どうぞ宜しくお願い申し上げます。</span></p>
                  <p>OPEN　 10:00-18:00<br />CLOSE　土日祝<br /><span>※臨時休業はInstagram公式アカウントにてご案内します。</span><br />ACCESS JR山手線 目白駅改札から徒歩2分</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>



        <!---------------------------------------------------------------------->
        <div
          id="online-shop"
          class="good-img bigImg2"
          @mouseenter="() => mouseEnterBigImg(2)"
          @mouseleave="() => mouseLeaveBigImg(2)"
        >
          <div class="good-img-mark-text">
            <div>
              <div class="bahnschrift-Light-Condensde good-img-mark-text-title">
                <div>Online</div>
                <div>Store</div>
              </div>
              <div class="good-img-mark-text-subTitle Meiryo cG2">
                オンライン店舗
              </div>
            </div>
          </div>
          <div class="good-img-mark"></div>
          <div class="good-img-o"></div>
        </div>
        <div class="shop">
          <div class="shop-imgs">
            <div
              class="shop-img-o shop-img5" style="background-color: #fff;"
              @mouseenter="() => mouseEnterShopImg(5)"
              @mouseleave="() => mouseLeaveShopImg(5)"
            >
              <img class="minMo" src="@/assets/img/ONLINE.png" alt="" />
            </div>
            <div
              class="shop-img-o cG2 shop-img6 minMo"
              @mouseenter="() => mouseEnterShopImg(6)"
              @mouseleave="() => mouseLeaveShopImg(6)"
            >
              <img class="minMo" src="@/assets/img/v3/Amazon_2.png" alt="" />
            </div>
            <div class="shop-img-o shop-img7"></div>
            <div
              class="shop-img-o onl-shop bgCFFF shop-img7"
              @mouseenter="() => mouseEnterShopImg(7)"
              @mouseleave="() => mouseLeaveShopImg(7)"
              @click="amazonClick"
            >
              <div class="onl-shop-arrow">
                <div class="bahnschrift-Light-Condensde">Shop on Amazon</div>
                <div><img src="@/assets/img/right.svg" alt="" /></div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>

        <!---------------------------------------------------------------------->
        <div
            id="other-channels"
            class="good-img bigImg3"
            @mouseenter="() => mouseEnterBigImg(3)"
            @mouseleave="() => mouseLeaveBigImg(3)"
        >
          <div class="good-img-mark-text">
            <div>
              <div class="bahnschrift-Light-Condensde good-img-mark-text-title">
                <div>Other</div>
                <div>Channels</div>
              </div>
              <div class="good-img-mark-text-subTitle Meiryo cG2">
                その他のチャネル
              </div>
            </div>
          </div>
          <div class="good-img-mark"></div>
          <div class="good-img-o"></div>
        </div>

        <div class="shop">

          <div class="shop-imgs">
            <div class="shop-img-o shop-img111 shop-block-text" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(111)" @mouseleave="() => mouseLeaveShopImg(111)">
              <p class="name">十八楼</p>
              <p class="code">〒 500-8009</p>
              <p class="add">10 Minatomachi, Gifu</p>
              <p class="tel">TEL: 05-8265-1551</p>
            </div>
            <div class="shop-img-o cG2 shop-img112 minMo" @mouseenter="() => mouseEnterShopImg(112)" @mouseleave="() => mouseLeaveShopImg(112)">
              <img class="minMo" src="@/assets/img/shop_list/img111.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img113 minMo" @mouseenter="() => mouseEnterShopImg(113)" @mouseleave="() => mouseLeaveShopImg(113)">
              <img class="minMo" src="@/assets/img/shop_list/img112.jpg" alt="" />
            </div>
          </div>

          <div class="shop-imgs">
            <div class="shop-img-o shop-img31 shop-block-text" @click="shopOther2Click" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(31)" @mouseleave="() => mouseLeaveShopImg(31)">
              <p class="name">Men’s Clara</p>
              <p class="code">〒 107-0062</p>
              <p class="add">Tokyo, Minato City,<br>Minamiaoyama,<br>5 Chome-3-25 B1-2F</p>
              <p class="tel">TEL: 03-6427-3791</p>
              <p class="url"><a href="https://mens-clara.com" target="_blank">https://mens-clara.com</a></p>
            </div>
            <div class="shop-img-o cG2 shop-img32 minMo" @mouseenter="() => mouseEnterShopImg(32)" @mouseleave="() => mouseLeaveShopImg(32)">
              <img class="minMo" src="@/assets/img/shop_list/img11.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img33 minMo" @mouseenter="() => mouseEnterShopImg(33)" @mouseleave="() => mouseLeaveShopImg(33)">
              <img class="minMo" src="@/assets/img/shop_list/img12.jpg" alt="" />
            </div>
          </div>

          <div class="shop-imgs">
            <div class="shop-img-o shop-img41 shop-block-text" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(41)" @mouseleave="() => mouseLeaveShopImg(41)">
              <p class="name">WELL AGING CLINIC<br>南青山</p>
              <p class="code">〒 107-0062</p>
              <p class="add">Tokyo, Minato City,<br>Minamiaoyama,<br>5 Chome-4-27 BARBIZON104 5階</p>
              <p class="tel">TEL: 03-6805-1920</p>
            </div>
            <div class="shop-img-o cG2 shop-img42 minMo" @mouseenter="() => mouseEnterShopImg(42)" @mouseleave="() => mouseLeaveShopImg(42)">
              <img class="minMo" src="@/assets/img/shop_list/img21.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img43 minMo" @mouseenter="() => mouseEnterShopImg(43)" @mouseleave="() => mouseLeaveShopImg(43)">
              <img class="minMo" src="@/assets/img/shop_list/img22.jpg" alt="" />
            </div>
          </div>

          <div class="shop-imgs">
            <div class="shop-img-o shop-img51 shop-block-text" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(51)" @mouseleave="() => mouseLeaveShopImg(51)">
              <p class="name">NEW FACE<br>AESTHETIC CLINIC<br>新宿院</p>
              <p class="code">〒 160-0021</p>
              <p class="add">Tokyo, Shinjuku City,<br>Kabukicho,<br>2 Chome-26-10 TMK03 4F ビル</p>
              <p class="tel">TEL: 03-6457-3434</p>
            </div>
            <div class="shop-img-o cG2 shop-img52 minMo" @mouseenter="() => mouseEnterShopImg(52)" @mouseleave="() => mouseLeaveShopImg(52)">
              <img class="minMo" src="@/assets/img/shop_list/img31.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img53 minMo" @mouseenter="() => mouseEnterShopImg(53)" @mouseleave="() => mouseLeaveShopImg(53)">
              <img class="minMo" src="@/assets/img/shop_list/img32.jpg" alt="" />
            </div>
          </div>

          <div class="shop-imgs">
            <div class="shop-img-o shop-img61 shop-block-text" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(61)" @mouseleave="() => mouseLeaveShopImg(61)">
              <p class="name">東急PLAZA 銀座 8F<br>ワンズライフジャパン</p>
              <p class="code">〒 104-0061</p>
              <p class="add">Tokyo, Chuo City,<br>5 Chome-2-1 Ginza</p>
            </div>
            <div class="shop-img-o cG2 shop-img62 minMo" @mouseenter="() => mouseEnterShopImg(62)" @mouseleave="() => mouseLeaveShopImg(62)">
              <img class="minMo" src="@/assets/img/shop_list/img41.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img63 minMo" @mouseenter="() => mouseEnterShopImg(63)" @mouseleave="() => mouseLeaveShopImg(63)">
              <img class="minMo" src="@/assets/img/shop_list/img42.jpg" alt="" />
            </div>
          </div>

          <div class="shop-imgs">
            <div class="shop-img-o shop-img951 shop-block-text" style="background-color: #fff;" @mouseenter="() => mouseEnterShopImg(951)" @mouseleave="() => mouseLeaveShopImg(951)">
              <p class="name">笹楽</p>
              <p class="code">〒 370-1105</p>
              <p class="add">Gunma Tamamura,<br>Sawa District,<br>Fukujima,2015番地13</p>
            </div>
            <div class="shop-img-o cG2 shop-img952 minMo" @mouseenter="() => mouseEnterShopImg(952)" @mouseleave="() => mouseLeaveShopImg(952)">
              <img class="minMo" src="@/assets/img/shop_list/img951.jpg" alt="" />
            </div>
            <div class="shop-img-o cG2 shop-img953 minMo" @mouseenter="() => mouseEnterShopImg(953)" @mouseleave="() => mouseLeaveShopImg(953)">
              <img class="minMo" src="@/assets/img/shop_list/img952.jpg" alt="" />
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img90"
               @mouseenter="() => mouseEnterShopImg(90)" @mouseleave="() => mouseLeaveShopImg(90)" @click="shopOther2Click">
            <div class="mobile_shop_info">
              <p class="name">十八楼</p>
              <p class="code">〒 500-8009</p>
              <p class="add">10 Minatomachi, Gifu</p>
              <p class="tel">TEL: 05-8265-1551</p>
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img91"
              @mouseenter="() => mouseEnterShopImg(91)" @mouseleave="() => mouseLeaveShopImg(91)" @click="shopOther2Click">
            <div class="mobile_shop_info">
              <p class="name">Men’s Clara</p>
              <p class="code">〒 107-0062</p>
              <p class="add">Tokyo, Minato City, Minamiaoyama, 5 Chome-3-25 B1-2F</p>
              <p class="tel">TEL: 03-6427-3791</p>
              <p class="url"><a href="https://mens-clara.com" target="_blank">https://mens-clara.com</a></p>
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img92"
               @mouseenter="() => mouseEnterShopImg(92)" @mouseleave="() => mouseLeaveShopImg(92)">
            <div class="mobile_shop_info">
              <p class="name">WELL AGING CLINIC<br>南青山</p>
              <p class="code">〒 107-0062</p>
              <p class="add">Tokyo, Minato City, Minamiaoyama, 5 Chome-4-27 BARBIZON104 5階</p>
              <p class="tel">TEL: 03-6805-1920</p>
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img93"
               @mouseenter="() => mouseEnterShopImg(93)" @mouseleave="() => mouseLeaveShopImg(93)">
            <div class="mobile_shop_info">
              <p class="name">NEW FACE AESTHETIC CLINIC<br>新宿院</p>
              <p class="code">〒 160-0021</p>
              <p class="add">Tokyo, Shinjuku City, Kabukicho, 2 Chome-26-10 TMK03 4F ビル</p>
              <p class="tel">TEL: 03-6457-3434</p>
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img94"
               @mouseenter="() => mouseEnterShopImg(94)" @mouseleave="() => mouseLeaveShopImg(94)">
            <div class="mobile_shop_info">
              <p class="name">東急PLAZA 銀座 8F<br>ワンズライフジャパン</p>
              <p class="code">〒 104-0061</p>
              <p class="add">Tokyo, Chuo City, 5 Chome-2-1 Ginza</p>
            </div>
          </div>

          <div class="mobile_shop_box shop-img-o onl-shop bgCFFF shop-img95"
               @mouseenter="() => mouseEnterShopImg(95)" @mouseleave="() => mouseLeaveShopImg(95)">
            <div class="mobile_shop_info">
              <p class="name">笹楽</p>
              <p class="code">〒 370-1105</p>
              <p class="add">Gunma Tamamura,<br>Sawa District,<br>Fukujima,2015番地13</p>
            </div>
          </div>


        </div>

      </div>
    </div>
    <commonGoods></commonGoods>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import commonHeader from "../components/commonHeader.vue";
import commonFooter from "../components/commonFooter.vue";
import commonGoods from "../components/commonGoods.vue";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ShopList",
  components: {
    commonHeader,
    commonFooter,
    commonGoods,
    commonCover
  },
  setup() {
    const phTitle = ref("Shop List");
    const phSubTitle = ref("");
    const imgPath = ref(require("@/assets/img/all.png"));
    const fixtext = ref(null);
    const imgStart1 = ref(null);

    onMounted(() => {
      // Get the elements
      const images = document.querySelectorAll(".good-img");
      const shop_images = document.querySelectorAll(".shop-img");
      const shop_img_os = document.querySelectorAll(".shop-img-o");
      const goods_main_imgs = document.querySelectorAll(".goods-main-img");
      // Animate the images
      images.forEach((image) => {
        gsap.from(image, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: image,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      // Animate the shop_images
      shop_images.forEach((shop_image) => {
        gsap.from(shop_image, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: shop_image,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      // Animate the shop_img_os
      shop_img_os.forEach((shop_img_o) => {
        gsap.from(shop_img_o, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: shop_img_o,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      // Animate the shop_img_os
      goods_main_imgs.forEach((goods_main_img) => {
        gsap.from(goods_main_img, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          lazy: false,
          scrollTrigger: {
            lazy: false,
            trigger: goods_main_img,
            start: "top 90%",
            toggleActions: "play none play none",
          },
        });
      });

      // gsap.to(fixtext.value, {
      //   opacity: 0.8,
      //   scrollTrigger: {
      //     trigger: imgStart1.value,
      //     start: "center center",
      //     end: "bottom center",
      //     scrub: 1,
      //     toggleActions: "play none none reverse",
      //     // markers: true,
      //   },
      // });

      gsap.set(fixtext.value, { autoAlpha: 0 })

      let tween = gsap.to(fixtext.value, { autoAlpha: 1, paused: true, duration: 0.5 })

      ScrollTrigger.create({
        trigger: imgStart1.value,
        start: 'center center',
        onEnter: ({progress, direction, isActive}) => { tween[direction === 1 ? "play" : "reverse"]() },
        onLeave: ({progress, direction, isActive}) => { tween[direction === 1 ? "reverse" : "play"]() },
        onEnterBack: ({progress, direction, isActive}) => { tween[direction === 1 ? "reverse" : "play"]() },
        onLeaveBack: ({progress, direction, isActive}) => { tween[direction === 1 ? "play" : "reverse"]() },
        scrub: true
      })

    });

    const mouseEnterBigImg = (index) => {
      gsap.to(".bigImg" + index, {
        y: -10,
        boxShadow: "6px 6px 24px #bbbbbb",
        duration: 0.5,
      });
    };

    const mouseLeaveBigImg = (index) => {
      gsap.to(".bigImg" + index, {
        y: 0,
        boxShadow: "6px 6px 14px #e6e6e6",
        duration: 0.5,
      });
    };

    const mouseEnterShopImg = (index) => {
      gsap.to(".shop-img" + index, {
        y: -10,
        boxShadow: "6px 6px 24px #bbbbbb",
        duration: 0.5,
      });
    };

    const mouseLeaveShopImg = (index) => {
      gsap.to(".shop-img" + index, {
        y: 0,
        // boxShadow: "6px 6px 14px #e6e6e6",
        boxShadow: "0px 0px 0px #f3f3f3",
        duration: 0.5,
      });
    };

    const mouseEnterPhyShop = () => {
      if (document.documentElement.clientWidth > 823) {
        gsap.to(".psy-shop", {
          width: "100%",
          duration: 0.4,
          onComplete: () => {
            gsap.to(".psy-shop-cover", { opacity: 1, duration: 0.3 });
            gsap.to(".psy-shop-arrow", { opacity: 0.4, duration: 0.3 });
            gsap.to(".psy-shop-text", { opacity: 1, duration: 0.3 });
          },
        });
      }
    };

    const mouseLeavePhyShop = () => {
      if (document.documentElement.clientWidth > 823) {
        gsap.killTweensOf(".psy-shop");
        gsap.killTweensOf(".psy-shop-cover");
        gsap.killTweensOf(".psy-shop-arrow");
        gsap.killTweensOf(".psy-shop-text");
        gsap.to(".psy-shop-text", { opacity: 0, duration: 0 });
        gsap.to(".psy-shop-arrow", { opacity: 1, duration: 0 });
        gsap.to(".psy-shop-cover", {
          opacity: 0,
          duration: 0,
          onComplete: () => {
            gsap.to(".psy-shop", { width: "22%", duration: 0.5 });
          },
        });
      }
    };

    const mouseEnterOnlShop = () => {
      if (document.documentElement.clientWidth > 823) {
        gsap.to(".onl-shop", {
          width: "100%",
          duration: 0.4,
          onComplete: () => {
            gsap.to(".onl-shop-cover", { opacity: 1, duration: 0.3 });
            gsap.to(".onl-shop-arrow", { opacity: 0.4, duration: 0.3 });
            gsap.to(".onl-shop-text", { opacity: 1, duration: 0.3 });
          },
        });
      }
    };

    const mouseLeaveOnlShop = () => {
      if (document.documentElement.clientWidth > 823) {
        gsap.killTweensOf(".onl-shop");
        gsap.killTweensOf(".onl-shop-cover");
        gsap.killTweensOf(".onl-shop-arrow");
        gsap.killTweensOf(".onl-shop-text");
        gsap.to(".onl-shop-text", { opacity: 0, duration: 0 });
        gsap.to(".onl-shop-arrow", { opacity: 1, duration: 0 });
        gsap.to(".onl-shop-cover", {
          opacity: 0,
          duration: 0,
          onComplete: () => {
            gsap.to(".onl-shop", { width: "30%", duration: 0.5 });
          },
        });
      }
    };

    const mouseEnterGoodImg = (index) => {
      gsap.to(".goods-main-img" + index, {
        y: -10,
        boxShadow: "6px 6px 24px #bbbbbb",
        duration: 0.5,
      });
    };

    const mouseLeaveGoodImg = (index) => {
      gsap.to(".goods-main-img" + index, {
        y: 10,
        boxShadow: "6px 6px 14px #e6e6e6",
        duration: 0.5,
      });
    };

    const amazonClick = () => {
      window.open('https://www.amazon.co.jp/stores/page/AF15BAA5-2773-4777-9721-D577DDD4ABE0', '_blank');
    };

    const shopOther2Click = () => {
      window.open('https://mens-clara.com', '_blank');
    };

    return {
      phTitle,
      phSubTitle,
      imgPath,
      fixtext,
      imgStart1,
      mouseEnterBigImg,
      mouseLeaveBigImg,
      mouseEnterShopImg,
      mouseLeaveShopImg,
      mouseEnterPhyShop,
      mouseLeavePhyShop,
      mouseEnterOnlShop,
      mouseLeaveOnlShop,
      mouseEnterGoodImg,
      mouseLeaveGoodImg,
      amazonClick,
      shopOther2Click
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1600px) {
  @import "@/styles/mediaShopList_min1600.scss";
}
@media screen and (min-width: 1300px) and (max-width: 1599px) {
  @import "@/styles/mediaShopList_min1599.scss";
}
@media screen and (min-width: 841px) and (max-width: 1299px) {
  @import "@/styles/mediaShopList_min1299.scss";
}
@media screen and (max-width: 840px) {
  @import "@/styles/mediaShopList_max840.scss";
}
</style>