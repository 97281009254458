<template>
  <div>
    <topMB v-if="isMobile"></topMB>
    <topPC v-else></topPC>
  </div>
</template>

<script>
import topPC from "./TopPC.vue";
import topMB from "./TopMB.vue";
export default {
  components: {
    topPC,
    topMB
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleResize() {
      this.isMobile = document.documentElement.clientWidth <= 440;
      const body = document.querySelector('body');
      const html = document.querySelector('html');
      if (this.isMobile) {
        console.log("mb")
        sessionStorage.setItem('screen','mb');
        body.style.overflowX = 'hidden';
        body.style.overflowY = '';
        body.style.width = '100vw';
        body.style.height = 'auto';
        html.style.overflowX = 'hidden';
        html.style.overflowY = '';
      } else {
        console.log("pc")
        sessionStorage.setItem('screen','pc');
        body.style.overflowX = 'hidden';
        body.style.height = '100vh';
        body.style.overflowY = '';
        // html.style.overflowX = 'hidden';
        // html.style.height = '100vh';
        // html.style.overflowY = 'revert';
      }
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>