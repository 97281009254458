import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products_healthS from '../views/Products_healthS.vue'
import Products_beautyS from '../views/Products_beautyS.vue'
import Products_skincareS from '../views/Products_skincareS.vue'
import About from '../views/About.vue'
import ShopList from '../views/ShopList.vue'
import test from '../views/test.vue'
import HomeViewTest from '../views/HomeViewTest.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			title: 'Bioagen_バイオエイジン_トキリン株式会社',
			keywords: 'Bioagen,バイオエイジン,ピローバイタル,ピュロー,トキリン株式会社',
			description: 'BIOAGENは、日本発グローバル展開のブランドです。特定の細胞に焦点を定め、エイジングを制御するメカニズムの解明を行っています。その効能効果を最大限生み出すべく研究を重ね、皆様にアンチエイジングソリューションを提供し、質の高い生活を支援しています。 専門性の高い開発チームの知恵と創造力を結集し、「PYRROVITAL」や「PUROL」などの人気商品が誕生しています。'
		}
	},
	{
		path: '/products_healthS',
		name: 'Products_healthS',
		component: Products_healthS,
	},
	{
		path: '/products_beautyS',
		name: 'Products_beautyS',
		component: Products_beautyS,
	},
	{
		path: '/products_skincareS',
		name: 'Products_skincareS',
		component: Products_skincareS,
	},
	{
		path: '/test',
		name: 'test',
		component: test,
	},
	{
		path: '/about',
		name: 'About',
		component: About,
	},
	{
		path: '/shopList',
		name: 'ShopList',
		component: ShopList,
	},
	{
		path: '/topTest',
		name: 'HomeViewTest',
		component: HomeViewTest,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }else if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});

const defaultValue = 'BIOAGEN'
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultValue
	document.getElementsByName('keywords')[0].content = to.meta.keywords ? to.meta.keywords : defaultValue
	document.getElementsByName('description')[0].content = to.meta.description ? to.meta.description : defaultValue
	next()
})

export default router;
