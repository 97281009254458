<template>
  <div class="overlay">
    <div class="cover-main">
      <div class="jitsu-img menuImg1">
        <img src="@/assets/img/1.png" alt="" />
      </div>
      <div class="on-img menuImg2">
        <img src="@/assets/img/2.png" alt="" />
      </div>
      <div class="close-overlay" v-show="menuFlg" @click="menuClose">
        <img src="@/assets/img/menu.png" alt="" />
      </div>
      <div class="overlay-main">
        <div class="overlay-main-left" @click="toTop">
          <img class="svg-logo" src="@/assets/svg/BIOAGENLOGO.svg" alt="" ref="svg" />
        </div>
        <div class="overlay-main-right">
          <div style="display: flex;justify-content: flex-end; margin-top: 20px;">
            <div style="color: #fff;margin: 0 10px;line-height: 30px">
              SOUND:
            </div>
            <div id="switch" @click="toggleSwitch" :class="{'active': isOn}">
              <div class="circle">
                <span class="switch-text">{{ switchText }}</span>
              </div>
            </div>
          </div>
          
          <div class="overlay-main-right-menu">
            <div>
              <div class="Baskerville-Old-Face menu-text menu-hover1"
                @mouseenter="() => mouseEnterMenu(1)"
                @mouseleave="() => mouseLeaveMenu(1)"
                @click="toTop"
              >Top
              </div>
              <div>
                <div class="Baskerville-Old-Face menu-text menu-hover2"
                     @mouseenter="() => mouseEnterMenu(2)"
                     @mouseleave="() => mouseLeaveMenu(2)"
                     @click="tohealthS"
                >Products</div>
                <div class="menu-text-sub2 Meiryo">
                  <div class="sub-product" @click="toProduct1">Health Support</div>
                  <div class="sub-product" @click="toProduct2">Beauty Support</div>
                  <div class="sub-product" @click="toProduct3">Skincare Series</div>
                </div>
              </div>
              <div>
                <div class="Baskerville-Old-Face menu-text menu-hover3"
                  @mouseenter="() => mouseEnterMenu(3)"
                  @mouseleave="() => mouseLeaveMenu(3)"
                  @click="toShopList"
                >Shop List</div>
                <div class="menu-text-sub Meiryo">
                  <div class="sub-shop hoverImg1"
                    @click="toJitsu"
                  >実店舗</div>
                  <div class="sub-shop hoverImg2"
                    @click="toOnline"
                  >オンライン店舗</div>
                  <div class="sub-shop hoverImg3"
                       @click="toOther"
                  >その他のチャネル</div>
                </div>
              </div>
              <div class="Baskerville-Old-Face menu-text menu-hover4"
                @mouseenter="() => mouseEnterMenu(4)"
                @mouseleave="() => mouseLeaveMenu(4)"
                @click="toAbout"
              >About</div>
              <div class="Baskerville-Old-Face menu-text menu-hover5"
                @mouseenter="() => mouseEnterMenu(5)"
                @mouseleave="() => mouseLeaveMenu(5)"
                @click="toContact"
              >Contact</div>
            </div>
          </div>
          <div class="share">
            <a href="https://instagram.com/bioagen" target="_blank">
              <div class="sns-area"
                @mouseenter="() => mouseEnterIns(1)"
                @mouseleave="() => mouseLeaveIns(1)"
              >
                <div class="sns-position">
                  <div class="sns icon-w1">
                    <img src="@/assets/img/ins-w.svg" alt="" />
                  </div>
                  <div class="sns icon-h1">
                    <img src="@/assets/img/ins-hover.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://lin.ee/HOIdxc2" target="_blank">
              <div class="line-area"
                @mouseenter="() => mouseEnterIns(2)"
                @mouseleave="() => mouseLeaveIns(2)"
              >
                <div class="sns-position">
                  <div class="sns icon-w2">
                    <img src="@/assets/img/v3/LINE_APP_W.png" alt="" />
                  </div>
                  <div class="sns icon-h2">
                    <img src="@/assets/img/v3/LINE_Brand_icon.png" alt="" />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://twitter.com/bioagen_japan?s=11&t=oOhE72Qfm-ezlPdR7DaIvQ" target="_blank">
              <div class="t-area"
                @mouseenter="() => mouseEnterIns(3)"
                @mouseleave="() => mouseLeaveIns(3)"
              >
                <div class="sns-position">
                  <div class="sns icon-w3">
                    <img src="@/assets/img/v3/Twitter-w.png" alt="" />
                  </div>
                  <div class="sns icon-h3">
                    <img src="@/assets/img/v3/Twitter-b.png" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "commonCover",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const menuFlg = computed(() => store.state.menuFlg);
    const path = route.path;
    const isOn = computed(() => store.state.isAudioOn);
    const switchText = ref(null);
 
    const menuClose = () => {
      store.dispatch('updateMenuFlg', !menuFlg.value);
    };

    watch(menuFlg, (newValue) => {
      changeBodyOverflow(newValue);
      if (newValue) {
        gsap.killTweensOf(".overlay");
        gsap.to(".overlay", {
          height: "100%",
          display: "",
          duration: 0.5,
          onComplete: handleMenuTextAnimationOnComplete,
        });
      } else {
        gsap.killTweensOf(".overlay");
        gsap.to(".overlay", {
          height: "0%",
          display: "none",
          duration: 0.5,
          onStart: handleMenuTextAnimationCloseComplete,
        });
      }
    });

    const handleMenuTextAnimationOnComplete = () => {
      gsap.to(".cover-main", {
        display: "flex",
        duration: 0,
      });
      gsap.to([".overlay-main", ".close-overlay"], {
        opacity: 1,
        duration: 0.5,
      });
    };

    const handleMenuTextAnimationCloseComplete = () => {
      gsap.to(".cover-main", {
        display: "none",
        duration: 0,
      });
      gsap.to([".overlay-main", ".close-overlay"], {
        opacity: 0,
        duration: 0.5,
      });
    };

    const mouseEnterIns = (index) => {
      gsap.to(".icon-w" + index, {
        opacity: 0,
        duration: 0.5,
      });
      gsap.to(".icon-h" + index, {
        opacity: 1,
        duration: 0.5,
      });
    };

    const mouseLeaveIns = (index) => {
      gsap.to(".icon-w" + index, {
        opacity: 1,
        duration: 0.5,
      });
      gsap.to(".icon-h" + index, {
        opacity: 0,
        duration: 0.5,
      });
    };

    const mouseEnterMenu = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".menu-hover" + index, {
          scale: 1.1,
          textShadow: '0px 0px 10px #0e0f1f90',
          duration: 0.3,
        });
      }
    };

    const mouseLeaveMenu = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".menu-hover" + index, {
          scale: 1,
          textShadow: 'none',
          duration: 0.3,
        });
      }
    };

    const mouseEnterImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".hoverImg" + index, {
          scale: 1.1,
          textShadow: '0px 0px 10px #0e0f1f90',
          duration: 0.3,
        });
        gsap.to(".menuImg" + index, {
          display: "flex",
          opacity: 1,
          duration: 0.6,
        });
      }
    };

    const mouseLeaveImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".hoverImg" + index, {
          scale: 1,
          textShadow: '0px 0px 10px #0e0f1f90',
          duration: 0.3,
        });
        gsap.to(".menuImg" + index, {
          display: "none",
          opacity: 0,
          duration: 0.6,
        });
      }
    };

    const toTop = () => {
      closeBodyOverflow();
      router.push('/');
    };

    const tohealthS = () => {
      closeBodyOverflow();
      router.push('/products_healthS');
    };

    const toAbout = () => {
      closeBodyOverflow();
      router.push('/about');
    };

    const toShopList = () => {
      closeBodyOverflow();
      router.push('/shopList');
    };

    const toJitsu = () => {
      closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#jitsu-shop' });
    };

    const toOnline = () => {
      closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#online-shop' });
    };

    const toOther = () => {
      closeBodyOverflow();
      router.push({ name: 'ShopList', hash: '#other-channels' });
    };

    const toProduct1 = () => {
      closeBodyOverflow();
      router.push({ name: 'Products_healthS' });
    };

    const toProduct2 = () => {
      closeBodyOverflow();
      router.push({ name: 'Products_beautyS'});
    };

    const toProduct3 = () => {
      closeBodyOverflow();
      router.push({ name: 'Products_skincareS'});
    };

    const toContact = () => {
      const subject = '';
      const body = '';
      // const mailtoString = `mailto:info@tkilyn.co.jp?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      const mailtoString = `mailto:info@tkilyn.co.jp`;
      window.location.href = mailtoString;
    };

    const closeBodyOverflow = () => {
      // gsap.to(".close-overlay", {
      //   display: "none",
      //   duration: 0,
      // });
      store.dispatch('updateMenuFlg', false);
      if (path === "/") {
        console.log("path",path)
        switch (document.documentElement.clientWidth <= 440) {
          case true:
            document.body.style.overflow = '';
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = '';
            break;
          case false:
            document.body.style.overflow = '';
            document.body.style.overflowX = '';
            document.body.style.overflowY = 'hidden';
            break;
          default:
            break;
        }
      } else {
        console.log("path2",path)
        document.body.style.overflow = '';
        document.body.style.overflowX = '';
        document.body.style.overflowY = '';
      }
    };

    const changeBodyOverflow = (newValue) => {
      const isMobile = document.documentElement.clientWidth <= 440;

      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        if (path === "/") {
          document.body.style.overflow = '';
          document.body.style.overflowX = isMobile ? '' : 'hidden';
          document.body.style.overflowY = isMobile ? 'hidden' : '';
        } else {
          console.log("document")
          document.body.style.overflow = '';
          document.body.style.overflowX = '';
          document.body.style.overflowY = '';
        }
      }
    };

    const toggleSwitch = () => {
      console.log("toggleSwitch",isOn.value)
      store.commit('toggleAudio');
      switchText.value = isOn.value ? 'ON' : 'OFF';
      animateSwitch();
    }

    const animateSwitch = () => {
      const finalPosition = store.state.isAudioOn ? '30px' : '0';
      gsap.to('.circle', {duration: 0.5, x: finalPosition});
    }

    watch(() => store.state.isAudioOn, (newValue) => {
      if (isOn.value !== newValue) {
        isOn.value = newValue;
        animateSwitch();
      }
    }, { immediate: true });

    onMounted(() => {
      console.log("isOn.value1",isOn.value)
      console.log("isOn.value11",store.state.isAudioOn)
      isOn.value = computed(() => store.state.isAudioOn);
      switchText.value = isOn.value ? 'ON' : 'OFF';
      console.log("isOn.value2",isOn.value)
      animateSwitch();
    });

    return {
      isOn,
      switchText,
      menuFlg,
      menuClose,
      mouseEnterIns,
      mouseLeaveIns,
      mouseEnterMenu,
      mouseLeaveMenu,
      mouseEnterImg,
      mouseLeaveImg,
      toTop,
      tohealthS,
      toAbout,
      toShopList,
      toContact,
      toJitsu,
      toOnline,
      toOther,
      toProduct1,
      toProduct2,
      toProduct3,
      toggleSwitch
    }
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1300px) {
	@import '@/styles/mediaCommonC_min1300.scss';
}
@media screen and (max-width: 1300px) and (min-width: 840px) {
	@import '@/styles/mediaCommonC_max1299.scss';
}
@media screen and (max-width: 840px) {
	@import '@/styles/mediaCommonC_max840.scss';
}
@media screen and (max-height: 650px) {
  .overlay-main-right {margin: -50px 0;}
}
</style>