import { createStore } from 'vuex'

export default createStore({
  state: {
    menuFlg: false,
    isAudioOn: false,
  },
  getters: {
  },
  mutations: {
    setMenuFlg(state, payload) {
      state.menuFlg = payload;
    },
    toggleAudio(state) {
      state.isAudioOn = !state.isAudioOn;
    },
  },
  actions: {
    updateMenuFlg({ commit }, payload) {
      commit('setMenuFlg', payload);
    },
  },
  modules: {
  }
})
