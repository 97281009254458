<template>
  <div>
    <commonHeader title="test" subTitle="test" imgPath=""></commonHeader>
    <!-- <commonMenu></commonMenu> -->
    <commonCover></commonCover>
    <commonGoods></commonGoods>
    <!-- <div class="overlay">
      <div class="overlay-text">text</div>
      <button class="close-overlay" @click="toggleOverlay">Close</button>
    </div> -->
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import commonHeader from '../components/commonHeader.vue'
import commonMenu from '../components/commonMenu.vue'
import commonGoods from '../components/commonGoods.vue'
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { computed } from 'vue';
import { useStore } from 'vuex';
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Products_healthS",
  components: {
    commonHeader,
    commonMenu,
    commonGoods,
    commonCover
  },
  setup() {
    const store = useStore();

    const menuFlg = computed(() => store.state.menuFlg);


    // const isOverlayVisible = ref(false);
    // // @click="$emit('overlayToggle')"
    // const toggleOverlay = () => {
    //   isOverlayVisible.value = !isOverlayVisible.value;
    //   document.body.style.overflow = isOverlayVisible.value ? 'hidden' : 'auto';
    //   if (isOverlayVisible.value) {
    //     animateText(); 
    //   } else {
    //     gsap.to(".overlay", {
    //       height: "0%",
    //       opacity: 1,
    //       duration: 0.5,
    //     });
    //   }
    // };

    // const animateText = () => {
    //   const text = document.querySelector(".overlay-text");
    //   gsap.from(text, {
    //     duration: 2,
    //     ease: "power2.out",
    //   });
    //   gsap.to(".overlay", {
    //     height: "100%",
    //     opacity: 1,
    //     duration: 0.5,
    //   });
    // };
    return {
      menuFlg
      // isOverlayVisible,
      // toggleOverlay
    }
  }
}
</script>
<style scoped>
/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.overlay-text {
  opacity: 0;
  color: #ffffff;
  font-size: 2rem;
}

.close-overlay {
  position: absolute;
  top: 40px;
  left: 40px;
  background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
} */
</style>