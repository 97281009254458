<template>
	<div>
		<div class="background-wrapper">
			<div class="bg-image bg-image-1">
				<img :src="imgT" alt="Health Series Background" />
			</div>
			<div class="bg-image bg-image-2">
				<img :src="imgB" alt="Beauty Series Background" />
			</div>
      <div class="bg-image bg-image-3">
        <img :src="imgC" alt="Skincare Series Background" />
      </div>
			<div class="menu-overlay"></div>
		</div>

		<div class="title-menu">

      <div class="background-wrapper">
				<div class="bg-image bg-image-1"></div>
        <div class="bg-image bg-image-2"></div>
        <div class="bg-image bg-image-3"></div>
				<div class="menu-overlay"></div>
			</div>

			<div class="title-menu-contents contents-1" @mouseenter="() => mouseEnter(1)" @mouseleave="mouseLeave"
				@click="goToProductsHealthS">
				<div class="title-menu-top-left bahnschrift-Light-Condensde">
					<div>
						<div>Health</div>
						<div>Support</div>
					</div>
				</div>
				<div class="title-menu-top-right">
					<div>
						<div class="title-menu-top-right-t Meiryo">
							<div>ヘルス</div>
							<div>サポート</div>
						</div>
						<div class="title-menu-top-right-b Meiryo">
							<div>今この瞬間も、この先の時間も</div>
							<div>心豊かに過ごす重要な鍵、健康をサポート</div>
						</div>
					</div>
				</div>
			</div>

			<div class="line"></div>
			<div class="title-menu-contents contents-2" @mouseenter="() => mouseEnter(2)" @mouseleave="mouseLeave"
				@click="goToProductsBeautyS">
				<div class="title-menu-top-left bahnschrift-Light-Condensde">
					<div>
						<div>Beauty</div>
						<div>Support</div>
					</div>
				</div>
				<div class="title-menu-top-right">
					<div>
						<div class="title-menu-top-right-t Meiryo">
							<div>ビューティー</div>
							<div>サポート</div>
						</div>
						<div class="title-menu-top-right-b Meiryo">
							<div>美しくあることは、自分らしくあること</div>
							<div>あなたらしく輝くための「美」へアプローチ</div>
						</div>
					</div>
				</div>
			</div>

      <div class="line"></div>
      <div class="title-menu-contents contents-3" @mouseenter="() => mouseEnter(3)" @mouseleave="mouseLeave"
           @click="goToProductsSkincareS">
        <div class="title-menu-top-left bahnschrift-Light-Condensde">
          <div>
            <div>Skincare</div>
            <div>Series</div>
          </div>
        </div>
        <div class="title-menu-top-right">
          <div>
            <div class="title-menu-top-right-t Meiryo">
              <div>スキンケア</div>
              <div>シリーズ</div>
            </div>
            <div class="title-menu-top-right-b Meiryo">
              <div>素肌に、気品。</div>
              <div>自分らしくゆるぎない美に</div>
              <div>出会うためのスキンケア</div>
            </div>
          </div>
        </div>
      </div>

		</div>

	</div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRouter } from 'vue-router';
gsap.registerPlugin(ScrollTrigger);

export default {
	name: "commonMenu",
	setup() {
		const router = useRouter();
		const mouseLeaveTimeout = ref(null);
		const imgT = ref(require('@/assets/img/all.png'));
		const imgB = ref(require('@/assets/img/good7.png'));
    const imgC = ref(require('@/assets/img/skincare_series_bottom_img.jpg'));
    const mouseEnter = (index) => {
			clearTimeout(mouseLeaveTimeout.value);
			// 根据index切换背景图片
			gsap.to(".bg-image", { opacity: 0, duration: 0.5 });
			gsap.to(".bg-image-" + index, { opacity: 0.8, duration: 0.5 });

			// 显示黑色透明蒙版
			gsap.to(".menu-overlay", { opacity: 0.6, duration: 0.5 });

			// 显示白色中间线
			gsap.to(".line", { borderTop: "1px white solid", opacity: 0.5 });

			// 改变文字颜色和透明度
			gsap.to(".contents-1", { color: "white", duration: 0.5, opacity: index == 1 ? 1 : 0.3 });
      gsap.to(".contents-2", { color: "white", duration: 0.5, opacity: index == 2 ? 1 : 0.3 });
      gsap.to(".contents-3", { color: "white", duration: 0.5, opacity: index == 3 ? 1 : 0.3 });

		};

		const mouseLeave = () => {
			// 设置100毫秒的延时
			mouseLeaveTimeout.value = setTimeout(() => {
				// 隐藏黑色透明蒙版
				gsap.to(".menu-overlay", { opacity: 0, duration: 0.3 });

				// 还原文字颜色
				gsap.to(".contents-1", { color: "#222", duration: 0.5, opacity: 1 });
        gsap.to(".contents-2", { color: "#222", duration: 0.5, opacity: 1 });
        gsap.to(".contents-3", { color: "#222", duration: 0.5, opacity: 1 });

				// 还原背景图片
				gsap.to(".bg-image", { opacity: 0, duration: 0.5 });

				// 还原中间线颜色
				gsap.to(".line", { borderTop: "1px #222 solid", opacity: 1 });
			}, 100);
		};

    setTimeout(function() {
      // 隐藏黑色透明蒙版
      gsap.to(".menu-overlay", { opacity: 0, duration: 0 });
    }, 100);

		function goToProductsHealthS() {
			router.push('/products_healthS');
		}

		function goToProductsBeautyS() {
			router.push('/products_beautyS');
		}

    function goToProductsSkincareS() {
      router.push('/products_skincareS');
    }

		return {
			mouseEnter,
			mouseLeave,
			goToProductsHealthS,
			goToProductsBeautyS,
      goToProductsSkincareS,
			imgT,
			imgB,
      imgC,
		};
	},
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 840px) {
	@import '@/styles/mediaCommonM_min841.scss';
}

@media screen and (max-width: 840px) {
	@import '@/styles/mediaCommonM_max840.scss';
}
</style>